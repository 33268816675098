import { EmoviToGuess } from "../interfaces/EmoviToGuess";

export const DAILY_EMOVI: Record<string, EmoviToGuess> = {
    "2022-07-17": {
        "id": "tt0114709",
        "emojiText": "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2022-07-18": {
        "id": "tt0167260",
        "emojiText": "💍🌋🧙‍♂️👑"
    },
    "2022-07-19": {
        "id": "tt0103639",
        "emojiText": "🧞‍♂️🪔🐒👸🤴"
    },
    "2022-07-20": {
        "id": "tt0211915",
        "emojiText": "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2022-07-21": {
        "id": "tt1745960",
        "emojiText": "✈️🇺🇸🕶️"
    },
    "2022-07-22": {
        "id": "tt0109830",
        "emojiText": "🏃🍫🦐"
    },
    "2022-07-23": {
        "id": "tt0120382",
        "emojiText": "🙍‍♂️🎥⛵😨📺"
    },
    "2022-07-24": {
        "id": "tt4633694",
        "emojiText": "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2022-07-25": {
        "id": "tt0090605",
        "emojiText": "👽🤰🩸😱"
    },
    "2022-07-26": {
        "id": "tt0062622",
        "emojiText": "👁️🖥️🛰️🚀"
    },
    "2022-07-27": {
        "id": "tt0054215",
        "emojiText": "🔪🚿🧓"
    },
    "2022-07-28": {
        "id": "tt0101414",
        "emojiText": "🌹👸🧌"
    },
    "2022-07-29": {
        "id": "tt0264464",
        "emojiText": "🏃‍♂️✈️💰🏃"
    },
    "2022-07-30": {
        "id": "tt0045152",
        "emojiText": "☔🎤😃"
    },
    "2022-07-31": {
        "id": "tt0107290",
        "emojiText": "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2022-08-01": {
        "id": "tt0993846",
        "emojiText": "🐺🧱🛣️〽️🏦💵"
    },
    "2022-08-02": {
        "id": "tt0382932",
        "emojiText": "🐭💆‍♂️👨‍🍳🍲"
    },
    "2022-08-03": {
        "id": "tt10648342",
        "emojiText": "🦸🔨❤️➕⛈️"
    },
    "2022-08-04": {
        "id": "tt0482571",
        "emojiText": "🏃🚪🎩🚪🏃▶️👬"
    },
    "2022-08-05": {
        "id": "tt2380307",
        "emojiText": "🎸💀👦🇲🇽"
    },
    "2022-08-06": {
        "id": "tt0107048",
        "emojiText": "🔄⏰🦔"
    },
    "2022-08-07": {
        "id": "tt0133093",
        "emojiText": "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2022-08-08": {
        "id": "tt1130884",
        "emojiText": "👮‍♂️💊🏝️😨"
    },
    "2022-08-09": {
        "id": "tt2293640",
        "emojiText": "🤓🤓🤓🍌🧒"
    },
    "2022-08-10": {
        "id": "tt0114369",
        "emojiText": "😋😛😴🤢😡😎🤤👀"
    },
    "2022-08-11": {
        "id": "tt5311514",
        "emojiText": "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2022-08-12": {
        "id": "tt0119217",
        "emojiText": "🧹🏫👨‍🏫🧠🍎"
    },
    "2022-08-13": {
        "id": "tt12412888",
        "emojiText": "🦔👟✌️"
    },
    "2022-08-14": {
        "id": "tt0088763",
        "emojiText": "🚗🔙⌚👨‍🔬🙍"
    },
    "2022-08-15": {
        "id": "tt0435761",
        "emojiText": "👨‍🚀🤠🏫🍓🧸"
    },
    "2022-08-16": {
        "id": "tt0116629",
        "emojiText": "🇺🇸🎆👽👊"
    },
    "2022-08-17": {
        "id": "tt0325980",
        "emojiText": "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2022-08-18": {
        "id": "tt6467266",
        "emojiText": "🐷🦍🦁🎹🎧🦔"
    },
    "2022-08-19": {
        "id": "tt1160419",
        "emojiText": "👩‍👦🗡️⏳🐛🪐"
    },
    "2022-08-20": {
        "id": "tt0066921",
        "emojiText": "👁️🎩🦯🥛🍊"
    },
    "2022-08-21": {
        "id": "tt1049413",
        "emojiText": "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2022-08-22": {
        "id": "tt2582802",
        "emojiText": "🥁👨‍🦲⏱️🩸🚗📁🎶"
    },
    "2022-08-23": {
        "id": "tt0120338",
        "emojiText": "💑🚢🧊🥶👵"
    },
    "2022-08-24": {
        "id": "tt2096673",
        "emojiText": "😡😭😊🤢😱🧠"
    },
    "2022-08-25": {
        "id": "tt0050083",
        "emojiText": "😠1️⃣2️⃣"
    },
    "2022-08-26": {
        "id": "tt1396484",
        "emojiText": "🤡👿🎈"
    },
    "2022-08-27": {
        "id": "tt0073195",
        "emojiText": "🦈😱"
    },
    "2022-08-28": {
        "id": "tt0137523",
        "emojiText": "🥊♣️🧼"
    },
    "2022-08-29": {
        "id": "tt1877830",
        "emojiText": "🦇🐱🐧❓"
    },
    "2022-08-30": {
        "id": "tt0088247",
        "emojiText": "🤖🕶️🔫"
    },
    "2022-08-31": {
        "id": "tt0110413",
        "emojiText": "🧔🔫🥛👧"
    },
    "2022-09-01": {
        "id": "tt0110912",
        "emojiText": "💼🕺💉💃☕⌚"
    },
    "2022-09-02": {
        "id": "tt0083658",
        "emojiText": "🗡️🏃‍♂️"
    },
    "2022-09-03": {
        "id": "tt4729430",
        "emojiText": "📫🧓🔨🧸🛷"
    },
    "2022-09-04": {
        "id": "tt2382320",
        "emojiText": "🚫🕐💀"
    },
    "2022-09-05": {
        "id": "tt1201607",
        "emojiText": "👦⚡☠️⚔️2️⃣"
    },
    "2022-09-06": {
        "id": "tt2024544",
        "emojiText": "🕛🗓️⛓️"
    },
    "2022-09-07": {
        "id": "tt0095016",
        "emojiText": "🗼🎄👨🔫"
    },
    "2022-09-08": {
        "id": "tt0041959",
        "emojiText": "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2022-09-09": {
        "id": "tt0070047",
        "emojiText": "✝️🤮🙏"
    },
    "2022-09-10": {
        "id": "tt0266697",
        "emojiText": "👱‍♀️⛩️⚔️"
    },
    "2022-09-11": {
        "id": "tt0081505",
        "emojiText": "🪓😈🛀"
    },
    "2022-09-12": {
        "id": "tt10298810",
        "emojiText": "👨‍🚀🌌"
    },
    "2022-09-13": {
        "id": "tt0167404",
        "emojiText": "👁️👀💀🧑‍🤝‍🧑"
    },
    "2022-09-14": {
        "id": "tt9032400",
        "emojiText": "🦸‍♂️🌍♾️"
    },
    "2022-09-15": {
        "id": "tt0317705",
        "emojiText": "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2022-09-16": {
        "id": "tt6751668",
        "emojiText": "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2022-09-17": {
        "id": "tt0266543",
        "emojiText": "🔎🐟"
    },
    "2022-09-18": {
        "id": "tt4154756",
        "emojiText": "♾️⚔️"
    },
    "2022-09-19": {
        "id": "tt0075148",
        "emojiText": "🥊"
    },
    "2022-09-20": {
        "id": "tt0112573",
        "emojiText": "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2022-09-21": {
        "id": "tt0816692",
        "emojiText": "🚀🌊⏲️🌽"
    },
    "2022-09-22": {
        "id": "tt0102926",
        "emojiText": "🤐🐑"
    },
    "2022-09-23": {
        "id": "tt0032138",
        "emojiText": "🐶👠👠🌪️🏠"
    },
    "2022-09-24": {
        "id": "tt0884328",
        "emojiText": "🌫️🛒🌫️"
    },
    "2022-09-25": {
        "id": "tt1375666",
        "emojiText": "💤🌀⤴️"
    },
    "2022-09-26": {
        "id": "tt0060196",
        "emojiText": "👍👎👹"
    },
    "2022-09-27": {
        "id": "tt0198781",
        "emojiText": "🟢👁️🟢👧👹"
    },
    "2022-09-28": {
        "id": "tt0892769",
        "emojiText": "⚫🐉🦷"
    },
    "2022-09-29": {
        "id": "tt0910970",
        "emojiText": "🌍🗑️🤖🌱❤️"
    },
    "2022-09-30": {
        "id": "tt8503618",
        "emojiText": "🇺🇸🎼🗽🎵🔫"
    },
    "2022-10-01": {
        "id": "tt0457430",
        "emojiText": "👁️👐👁️"
    },
    "2022-10-02": {
        "id": "tt0118715",
        "emojiText": "🎳🥛💼🩲"
    },
    "2022-10-03": {
        "id": "tt0169547",
        "emojiText": "🇺🇸💅🌹🌹🌹🌹🌹🌹"
    },
    "2022-10-04": {
        "id": "tt0056172",
        "emojiText": "🏇🏜️🗡️"
    },
    "2022-10-05": {
        "id": "tt1345836",
        "emojiText": "🦇🤵⬆️"
    },
    "2022-10-06": {
        "id": "tt0102926",
        "emojiText": "🔇🐏"
    },
    "2022-10-07": {
        "id": "tt0034583",
        "emojiText": "🇫🇷💖🚂😒🕌☕🎹✈️"
    },
    "2022-10-08": {
        "id": "tt1028532",
        "emojiText": "🐶🙍‍♂️🚋⏱️"
    },
    "2022-10-09": {
        "id": "tt0119698",
        "emojiText": "🐺👸🩸🐗🐛"
    },
    "2022-10-10": {
        "id": "tt0090605",
        "emojiText": "🌐👩👧🐱💥👽"
    },
    "2022-10-11": {
        "id": "tt0053291",
        "emojiText": "👨‍🦰👨‍🦱👠💄👗"
    },
    "2022-10-12": {
        "id": "tt0110357",
        "emojiText": "🦁🐆🐗👑"
    },
    "2022-10-13": {
        "id": "tt0245429",
        "emojiText": "🐉😱👹👵⛩️"
    },
    "2022-10-14": {
        "id": "tt0117951",
        "emojiText": "💉👬🧑‍🤝‍🧑💊🇬🇧🤪🤮"
    },
    "2022-10-15": {
        "id": "tt7657566",
        "emojiText": "👨🔎🇪🇬⛴️☠️"
    },
    "2022-10-16": {
        "id": "tt0096283",
        "emojiText": "▶️🚪🏠🐻"
    },
    "2022-10-17": {
        "id": "tt4513678",
        "emojiText": "🙍🙍🙍🙍👻🧹"
    },
    "2022-10-18": {
        "id": "tt1464335",
        "emojiText": "🗺️🧗‍♂️🚁💰"
    },
    "2022-10-19": {
        "id": "tt0338013",
        "emojiText": "💑💔😭💵👨‍🔬😴🧠💻🕵️🙂"
    },
    "2022-10-20": {
        "id": "tt0068646",
        "emojiText": "🐴🛏️🩸"
    },
    "2022-10-21": {
        "id": "tt0082971",
        "emojiText": "🏜️🕌💼💀🤠"
    },
    "2022-10-22": {
        "id": "tt6264654",
        "emojiText": "🎮🔫🧑‍💻⌨️🏦"
    },
    "2022-10-23": {
        "id": "tt0017136",
        "emojiText": "🤖🌆🇩🇪🎥"
    },
    "2022-10-24": {
        "id": "tt0120689",
        "emojiText": "💀🚶‍♂️🟩🟩🟩"
    },
    "2022-10-25": {
        "id": "tt0111161",
        "emojiText": "👨‍⚖️😧♟️⛏️👋"
    },
    "2022-10-26": {
        "id": "tt0172495",
        "emojiText": "🤼‍♂️🏟️⚔️👎"
    },
    "2022-10-27": {
        "id": "tt1201607",
        "emojiText": "👦⚡🔺💫🚫👃☠️"
    },
    "2022-10-28": {
        "id": "tt8772262",
        "emojiText": "💐🔪🐻"
    },
    "2022-10-29": {
        "id": "tt1853728",
        "emojiText": "🦷🤠🧐🏇⛓️"
    },
    "2022-10-30": {
        "id": "tt0114814",
        "emojiText": "👮‍♂️🤬🔫🕵️‍♂️❔"
    },
    "2022-10-31": {
        "id": "tt0095327",
        "emojiText": "👨‍👧⚰️🌌🌂🇯🇵"
    },
    "2022-11-01": {
        "id": "tt0053198",
        "emojiText": "👦💔🏃‍♂️🇫🇷"
    },
    "2022-11-02": {
        "id": "tt7286456",
        "emojiText": "🔫🤡🃏"
    },
    "2022-11-03": {
        "id": "tt0253474",
        "emojiText": "🧑🎹"
    },
    "2022-11-04": {
        "id": "tt11703710",
        "emojiText": "🎩👒📽️🎙️🇬🇧"
    },
    "2022-11-05": {
        "id": "tt0057115",
        "emojiText": "🇩🇪🕳️⛏️🏍️🚞"
    },
    "2022-11-06": {
        "id": "tt1130884",
        "emojiText": "🏝️🧐🏨"
    },
    "2022-11-07": {
        "id": "tt0100157",
        "emojiText": "⌨️👩‍🦼🔨👩‍🦰"
    },
    "2022-11-08": {
        "id": "tt15521050",
        "emojiText": "❤️🍦"
    },
    "2022-11-09": {
        "id": "tt0076759",
        "emojiText": "⭐🔫💣"
    },
    "2022-11-10": {
        "id": "tt0081398",
        "emojiText": "😡🐂"
    },
    "2022-11-11": {
        "id": "tt0092099",
        "emojiText": "🔝🔫🛩"
    },
    "2022-11-12": {
        "id": "tt0105236",
        "emojiText": "🚧🌊🚧🐶"
    },
    "2022-11-13": {
        "id": "tt1050263",
        "emojiText": "❄◽"
    },
    "2022-11-14": {
        "id": "tt0106965",
        "emojiText": "🆓🐳"
    },
    "2022-11-15": {
        "id": "tt0397892",
        "emojiText": "🐶⚡"
    },
    "2022-11-16": {
        "id": "tt11245972",
        "emojiText": "😱🔪"
    },
    "2022-11-17": {
        "id": "tt0118571",
        "emojiText": "🛩🇺🇸1"
    },
    "2022-11-18": {
        "id": "tt0119654",
        "emojiText": "👨🏻⤵⬛👽"
    },
    "2022-11-19": {
        "id": "tt0119695",
        "emojiText": "💰👄"
    },
    "2022-11-20": {
        "id": "tt0120632",
        "emojiText": "🏙👼🏻👼🏻"
    },
    "2022-11-21": {
        "id": "tt0120812",
        "emojiText": "🚗💨🕑"
    },
    "2022-11-22": {
        "id": "tt0250494",
        "emojiText": "⚖👩🏼👛🎥"
    },
    "2022-11-23": {
        "id": "tt0375679",
        "emojiText": "🚙💥🚗"
    },
    "2022-11-24": {
        "id": "tt0437405",
        "emojiText": "📄👨🏻"
    },
    "2022-11-25": {
        "id": "tt0417217",
        "emojiText": "2➡💰"
    },
    "2022-11-26": {
        "id": "tt0112431",
        "emojiText": "🐷💬🎥"
    },
    "2022-11-27": {
        "id": "tt0086034",
        "emojiText": "🐙🐱"
    },
    "2022-11-28": {
        "id": "tt6139732",
        "emojiText": "👳🏻🔮🌙"
    },
    "2022-11-29": {
        "id": "tt0053285",
        "emojiText": "💤👩🏻"
    },
    "2022-11-30": {
        "id": "tt0096244",
        "emojiText": "🍹🌄"
    },
    "2022-12-01": {
        "id": "tt0097239",
        "emojiText": "🚘👵🏻🌼"
    },
    "2022-12-02": {
        "id": "tt0253474",
        "emojiText": "👨🏻🎹"
    },
    "2022-12-03": {
        "id": "tt9347730",
        "emojiText": "🍬👨🏻"
    },
    "2022-12-04": {
        "id": "tt0061852",
        "emojiText": "🌴📖"
    },
    "2022-12-05": {
        "id": "tt0111257",
        "emojiText": "💣🚌💨💨"
    },
    "2022-12-06": {
        "id": "tt0113117",
        "emojiText": "🇫🇷💋"
    },
    "2022-12-07": {
        "id": "tt0039536",
        "emojiText": "💋💀"
    },
    "2022-12-08": {
        "id": "tt0050083",
        "emojiText": "12😡👨🏻"
    },
    "2022-12-09": {
        "id": "tt1602620",
        "emojiText": "👴🏻❤👵🏻"
    },
    "2022-12-10": {
        "id": "tt0133152",
        "emojiText": "🌍🐵🐵🐵"
    },
    "2022-12-11": {
        "id": "tt0120338",
        "emojiText": "🛳🌊💥"
    },
    "2022-12-12": {
        "id": "tt0157503",
        "emojiText": "💧💀👸🏻✨"
    },
    "2022-12-13": {
        "id": "tt0161081",
        "emojiText": "❓🛌🏻⬇"
    },
    "2022-12-14": {
        "id": "tt0163187",
        "emojiText": "🏃🏻👰🏻"
    },
    "2022-12-15": {
        "id": "tt0175142",
        "emojiText": "😱🎬"
    },
    "2022-12-16": {
        "id": "tt0448694",
        "emojiText": "😺👢"
    },
    "2022-12-17": {
        "id": "tt4593060",
        "emojiText": "👃🏻➡➡➡"
    },
    "2022-12-18": {
        "id": "tt0044706",
        "emojiText": "⬆🕛"
    },
    "2022-12-19": {
        "id": "tt0250687",
        "emojiText": "🐭🏃🏻🏃🏻🏃🏻"
    },
    "2022-12-20": {
        "id": "tt0259324",
        "emojiText": "👻👨🏻🐎"
    },
    "2022-12-21": {
        "id": "tt0259711",
        "emojiText": "🍦⛅"
    },
    "2022-12-22": {
        "id": "tt0265086",
        "emojiText": "⬛🐦⬇"
    },
    "2022-12-23": {
        "id": "tt1210819",
        "emojiText": "1👨🏻🐴"
    },
    "2022-12-24": {
        "id": "tt0960731",
        "emojiText": "😴📖"
    },
    "2022-12-25": {
        "id": "tt0358273",
        "emojiText": "🚶🏻➡➖➖"
    },
    "2022-12-26": {
        "id": "tt0360717",
        "emojiText": "👑🐵😡🏬"
    },
    "2022-12-27": {
        "id": "tt0366548",
        "emojiText": "😃👣"
    },
    "2022-12-28": {
        "id": "tt0371746",
        "emojiText": "ࠦ👨🏻"
    },
    "2022-12-29": {
        "id": "tt0377471",
        "emojiText": "🐝🆒"
    },
    "2022-12-30": {
        "id": "tt0389790",
        "emojiText": "🐝🎬"
    },
    "2022-12-31": {
        "id": "tt0396269",
        "emojiText": "👰🏻🤵🏻💥💥"
    },
    "2023-01-01": {
        "id": "tt0401792",
        "emojiText": "😈😈😈🌇"
    },
    "2023-01-02": {
        "id": "tt0409459",
        "emojiText": "⌚👨🏽👨🏽"
    },
    "2023-01-03": {
        "id": "tt0415778",
        "emojiText": "🔚🎮"
    },
    "2023-01-04": {
        "id": "tt0417148",
        "emojiText": "🐍🐍🛩"
    },
    "2023-01-05": {
        "id": "tt0441773",
        "emojiText": "👊🏻💥🐼"
    },
    "2023-01-06": {
        "id": "tt0448157",
        "emojiText": "🖐🏻🐓"
    },
    "2023-01-07": {
        "id": "tt0450259",
        "emojiText": "💉💎"
    },
    "2023-01-08": {
        "id": "tt0454876",
        "emojiText": "👳🏻🚣🏻♂🐅"
    },
    "2023-01-09": {
        "id": "tt0472181",
        "emojiText": "🔵👴🏻👧🏻👦🏻"
    },
    "2023-01-10": {
        "id": "tt0499549",
        "emojiText": "🔵👽"
    },
    "2023-01-11": {
        "id": "tt0800369",
        "emojiText": "⚡🔨💪🏻"
    },
    "2023-01-12": {
        "id": "tt0879870",
        "emojiText": "🍜🙏🏻❤"
    },
    "2023-01-13": {
        "id": "tt0942385",
        "emojiText": "🌴🍹⚡☔"
    },
    "2023-01-14": {
        "id": "tt0961097",
        "emojiText": "👹➡🇫🇷🗼"
    },
    "2023-01-15": {
        "id": "tt0978621",
        "emojiText": "🏃🏻💥⬅🚂"
    },
    "2023-01-16": {
        "id": "tt1605783",
        "emojiText": "🌃🕛🇫🇷🗼"
    },
    "2023-01-17": {
        "id": "tt1074638",
        "emojiText": "⛅🍃"
    },
    "2023-01-18": {
        "id": "tt2103264",
        "emojiText": "👑🇨🇳"
    },
    "2023-01-19": {
        "id": "tt1276104",
        "emojiText": "👨🏻🔫👦🏻🔄"
    },
    "2023-01-20": {
        "id": "tt1289401",
        "emojiText": "👻👻🔫"
    },
    "2023-01-21": {
        "id": "tt1416801",
        "emojiText": "🔫➡🍀👨🏻"
    },
    "2023-01-22": {
        "id": "tt1424381",
        "emojiText": "🐆🐅🐊"
    },
    "2023-01-23": {
        "id": "tt14403842",
        "emojiText": "🔟☔"
    },
    "2023-01-24": {
        "id": "tt1860353",
        "emojiText": "🐌💨"
    },
    "2023-01-25": {
        "id": "tt1637725",
        "emojiText": "🐻❤🍻🚬"
    },
    "2023-01-26": {
        "id": "tt1790885",
        "emojiText": "0🌌30"
    },
    "2023-01-27": {
        "id": "tt1798709",
        "emojiText": "👨🏻👓❤📱"
    },
    "2023-01-28": {
        "id": "tt1885300",
        "emojiText": "👌🏻👨🏻⬇"
    },
    "2023-01-29": {
        "id": "tt0054215",
        "emojiText": "🔪🚿"
    },
    "2023-01-30": {
        "id": "tt1981677",
        "emojiText": "🎤🎼💯"
    },
    "2023-01-31": {
        "id": "tt0099785",
        "emojiText": "🏠😱🎄👱🏻"
    },
    "2023-02-01": {
        "id": "tt2334879",
        "emojiText": "◽🏠⬇"
    },
    "2023-02-02": {
        "id": "tt0021749",
        "emojiText": "🏙💡💡"
    },
    "2023-02-03": {
        "id": "tt0105236",
        "emojiText": "🚧🌊🚧🐶"
    },
    "2023-02-04": {
        "id": "tt1049413",
        "emojiText": "🏠🎈🎈🎈"
    },
    "2023-02-05": {
        "id": "tt0068646",
        "emojiText": "🇮🇹😇👴🏻"
    },
    "2023-02-06": {
        "id": "tt0109830",
        "emojiText": "🏃🏻🍫🍤"
    },
    "2023-02-07": {
        "id": "tt0137523",
        "emojiText": "👊🏻♣"
    },
    "2023-02-08": {
        "id": "tt0266543",
        "emojiText": "🔎🐠"
    },
    "2023-02-09": {
        "id": "tt1375666",
        "emojiText": "💡➡😴"
    },
    "2023-02-10": {
        "id": "tt0114709",
        "emojiText": "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2023-02-11": {
        "id": "tt0167260",
        "emojiText": "💍🌋🧙‍♂️👑"
    },
    "2023-02-12": {
        "id": "tt6139732",
        "emojiText": "🧞‍♂️🪔🐒👸🤴"
    },
    "2023-02-13": {
        "id": "tt0211915",
        "emojiText": "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2023-02-14": {
        "id": "tt1745960",
        "emojiText": "✈️🇺🇸🕶️"
    },
    "2023-02-15": {
        "id": "tt0109830",
        "emojiText": "🏃🍫🦐"
    },
    "2023-02-16": {
        "id": "tt0120382",
        "emojiText": "🙍‍♂️🎥⛵😨📺"
    },
    "2023-02-17": {
        "id": "tt4633694",
        "emojiText": "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2023-02-18": {
        "id": "tt0090605",
        "emojiText": "👽🤰🩸😱"
    },
    "2023-02-19": {
        "id": "tt0062622",
        "emojiText": "👁️🖥️🛰️🚀"
    },
    "2023-02-20": {
        "id": "tt0054215",
        "emojiText": "🔪🚿🧓"
    },
    "2023-02-21": {
        "id": "tt0101414",
        "emojiText": "🌹👸🧌"
    },
    "2023-02-22": {
        "id": "tt0264464",
        "emojiText": "🏃‍♂️✈️💰🏃"
    },
    "2023-02-23": {
        "id": "tt0045152",
        "emojiText": "☔🎤😃"
    },
    "2023-02-24": {
        "id": "tt0107290",
        "emojiText": "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2023-02-25": {
        "id": "tt0382932",
        "emojiText": "🐭💆‍♂️👨‍🍳🍲"
    },
    "2023-02-26": {
        "id": "tt10648342",
        "emojiText": "🦸🔨❤️➕⛈️"
    },
    "2023-02-27": {
        "id": "tt0482571",
        "emojiText": "🏃🚪🎩🚪🏃▶️👬"
    },
    "2023-02-28": {
        "id": "tt2380307",
        "emojiText": "🎸💀👦🇲🇽"
    },
    "2023-03-01": {
        "id": "tt0107048",
        "emojiText": "🔄⏰🦔"
    },
    "2023-03-02": {
        "id": "tt0133093",
        "emojiText": "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2023-03-03": {
        "id": "tt1130884",
        "emojiText": "👮‍♂️💊🏝️😨"
    },
    "2023-03-04": {
        "id": "tt0114369",
        "emojiText": "😋😛😴🤢😡😎🤤👀"
    },
    "2023-03-05": {
        "id": "tt5311514",
        "emojiText": "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2023-03-06": {
        "id": "tt0119217",
        "emojiText": "🧹🏫👨‍🏫🧠🍎"
    },
    "2023-03-07": {
        "id": "tt12412888",
        "emojiText": "🦔👟✌️"
    },
    "2023-03-08": {
        "id": "tt0088763",
        "emojiText": "🚗🔙⌚👨‍🔬🙍"
    },
    "2023-03-09": {
        "id": "tt0435761",
        "emojiText": "👨‍🚀🤠🏫🍓🧸"
    },
    "2023-03-10": {
        "id": "tt0116629",
        "emojiText": "🇺🇸🎆👽👊"
    },
    "2023-03-11": {
        "id": "tt0325980",
        "emojiText": "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2023-03-12": {
        "id": "tt6467266",
        "emojiText": "🐷🦍🦁🎹🎧🦔"
    },
    "2023-03-13": {
        "id": "tt1160419",
        "emojiText": "👩‍👦🗡️⏳🐛🪐"
    },
    "2023-03-14": {
        "id": "tt0066921",
        "emojiText": "👁️🎩🦯🥛🍊"
    },
    "2023-03-15": {
        "id": "tt1049413",
        "emojiText": "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2023-03-16": {
        "id": "tt0120338",
        "emojiText": "💑🚢🧊🥶👵"
    },
    "2023-03-17": {
        "id": "tt0050083",
        "emojiText": "😠1️⃣2️⃣"
    },
    "2023-03-18": {
        "id": "tt0073195",
        "emojiText": "🦈😱"
    },
    "2023-03-19": {
        "id": "tt0137523",
        "emojiText": "🥊♣️🧼"
    },
    "2023-03-20": {
        "id": "tt0088247",
        "emojiText": "🤖🕶️🔫"
    },
    "2023-03-21": {
        "id": "tt0110413",
        "emojiText": "🧔🔫🥛👧"
    },
    "2023-03-22": {
        "id": "tt0110912",
        "emojiText": "💼🕺💉💃☕⌚"
    },
    "2023-03-23": {
        "id": "tt0083658",
        "emojiText": "🗡️🏃‍♂️"
    },
    "2023-03-24": {
        "id": "tt4729430",
        "emojiText": "📫🧓🔨🧸🛷"
    },
    "2023-03-25": {
        "id": "tt2382320",
        "emojiText": "🚫🕐💀"
    },
    "2023-03-26": {
        "id": "tt1201607",
        "emojiText": "👦⚡☠️⚔️2️⃣"
    },
    "2023-03-27": {
        "id": "tt0095016",
        "emojiText": "🗼🎄👨🔫"
    },
    "2023-03-28": {
        "id": "tt0041959",
        "emojiText": "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2023-03-29": {
        "id": "tt0070047",
        "emojiText": "✝️🤮🙏"
    },
    "2023-03-30": {
        "id": "tt0266697",
        "emojiText": "👱‍♀️⛩️⚔️"
    },
    "2023-03-31": {
        "id": "tt0081505",
        "emojiText": "🪓😈🛀"
    },
    "2023-04-01": {
        "id": "tt10298810",
        "emojiText": "👨‍🚀🌌"
    },
    "2023-04-02": {
        "id": "tt0167404",
        "emojiText": "👁️👀💀🧑‍🤝‍🧑"
    },
    "2023-04-03": {
        "id": "tt9032400",
        "emojiText": "🦸‍♂️🌍♾️"
    },
    "2023-04-04": {
        "id": "tt0317705",
        "emojiText": "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2023-04-05": {
        "id": "tt6751668",
        "emojiText": "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2023-04-06": {
        "id": "tt0266543",
        "emojiText": "🔎🐟"
    },
    "2023-04-07": {
        "id": "tt0075148",
        "emojiText": "🥊"
    },
    "2023-04-08": {
        "id": "tt0112573",
        "emojiText": "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2023-04-09": {
        "id": "tt0816692",
        "emojiText": "🚀🌊⏲️🌽"
    },
    "2023-04-10": {
        "id": "tt0102926",
        "emojiText": "🤐🐑"
    },
    "2023-04-11": {
        "id": "tt0884328",
        "emojiText": "🌫️🛒🌫️"
    },
    "2023-04-12": {
        "id": "tt1375666",
        "emojiText": "💤🌀⤴️"
    },
    "2023-04-13": {
        "id": "tt0060196",
        "emojiText": "👍👎👹"
    },
    "2023-04-14": {
        "id": "tt0198781",
        "emojiText": "🟢👁️🟢👧👹"
    },
    "2023-04-15": {
        "id": "tt0892769",
        "emojiText": "⚫🐉🦷"
    },
    "2023-04-16": {
        "id": "tt0910970",
        "emojiText": "🌍🗑️🤖🌱❤️"
    },
    "2023-04-17": {
        "id": "tt8503618",
        "emojiText": "🇺🇸🎼🗽🎵🔫"
    },
    "2023-04-18": {
        "id": "tt0457430",
        "emojiText": "👁️👐👁️"
    },
    "2023-04-19": {
        "id": "tt0118715",
        "emojiText": "🎳🥛💼🩲"
    },
    "2023-04-20": {
        "id": "tt0169547",
        "emojiText": "🇺🇸💅🌹🌹🌹🌹🌹🌹"
    },
    "2023-04-21": {
        "id": "tt0056172",
        "emojiText": "🏇🏜️🗡️"
    },
    "2023-04-22": {
        "id": "tt1345836",
        "emojiText": "🦇🤵⬆️"
    },
    "2023-04-23": {
        "id": "tt0102926",
        "emojiText": "🔇🐏"
    },
    "2023-04-24": {
        "id": "tt1375666",
        "emojiText": "💡➡😴"
    },
    "2023-04-25": {
        "id": "tt0114709",
        "emojiText": "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2023-04-26": {
        "id": "tt0167260",
        "emojiText": "💍🌋🧙‍♂️👑"
    },
    "2023-04-27": {
        "id": "tt6139732",
        "emojiText": "🧞‍♂️🪔🐒👸🤴"
    },
    "2023-04-28": {
        "id": "tt0211915",
        "emojiText": "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2023-04-29": {
        "id": "tt1745960",
        "emojiText": "✈️🇺🇸🕶️"
    },
    "2023-04-30": {
        "id": "tt0109830",
        "emojiText": "🏃🍫🦐"
    },
    "2023-04-31": {
        "id": "tt0120382",
        "emojiText": "🙍‍♂️🎥⛵😨📺"
    },
    "2023-05-01": {
        "id": "tt4633694",
        "emojiText": "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2023-05-02": {
        "id": "tt0090605",
        "emojiText": "👽🤰🩸😱"
    },
    "2023-05-03": {
        "id": "tt0062622",
        "emojiText": "👁️🖥️🛰️🚀"
    },
    "2023-05-04": {
        "id": "tt0054215",
        "emojiText": "🔪🚿🧓"
    },
    "2023-05-05": {
        "id": "tt0101414",
        "emojiText": "🌹👸🧌"
    },
    "2023-05-06": {
        "id": "tt0264464",
        "emojiText": "🏃‍♂️✈️💰🏃"
    },
    "2023-05-07": {
        "id": "tt0045152",
        "emojiText": "☔🎤😃"
    },
    "2023-05-08": {
        "id": "tt0107290",
        "emojiText": "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2023-05-09": {
        "id": "tt0382932",
        "emojiText": "🐭💆‍♂️👨‍🍳🍲"
    },
    "2023-05-10": {
        "id": "tt10648342",
        "emojiText": "🦸🔨❤️➕⛈️"
    },
    "2023-05-11": {
        "id": "tt0482571",
        "emojiText": "🏃🚪🎩🚪🏃▶️👬"
    },
    "2023-05-12": {
        "id": "tt2380307",
        "emojiText": "🎸💀👦🇲🇽"
    },
    "2023-05-13": {
        "id": "tt0107048",
        "emojiText": "🔄⏰🦔"
    },
    "2023-05-14": {
        "id": "tt0133093",
        "emojiText": "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2023-05-15": {
        "id": "tt1130884",
        "emojiText": "👮‍♂️💊🏝️😨"
    },
    "2023-05-16": {
        "id": "tt0114369",
        "emojiText": "😋😛😴🤢😡😎🤤👀"
    },
    "2023-05-17": {
        "id": "tt5311514",
        "emojiText": "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2023-05-18": {
        "id": "tt0119217",
        "emojiText": "🧹🏫👨‍🏫🧠🍎"
    },
    "2023-05-19": {
        "id": "tt12412888",
        "emojiText": "🦔👟✌️"
    },
    "2023-05-20": {
        "id": "tt0088763",
        "emojiText": "🚗🔙⌚👨‍🔬🙍"
    },
    "2023-05-21": {
        "id": "tt0435761",
        "emojiText": "👨‍🚀🤠🏫🍓🧸"
    },
    "2023-05-22": {
        "id": "tt0116629",
        "emojiText": "🇺🇸🎆👽👊"
    },
    "2023-05-23": {
        "id": "tt0325980",
        "emojiText": "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2023-05-24": {
        "id": "tt6467266",
        "emojiText": "🐷🦍🦁🎹🎧🦔"
    },
    "2023-05-25": {
        "id": "tt1160419",
        "emojiText": "👩‍👦🗡️⏳🐛🪐"
    },
    "2023-05-26": {
        "id": "tt0066921",
        "emojiText": "👁️🎩🦯🥛🍊"
    },
    "2023-05-27": {
        "id": "tt1049413",
        "emojiText": "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2023-05-28": {
        "id": "tt0120338",
        "emojiText": "💑🚢🧊🥶👵"
    },
    "2023-05-29": {
        "id": "tt0050083",
        "emojiText": "😠1️⃣2️⃣"
    },
    "2023-05-30": {
        "id": "tt0073195",
        "emojiText": "🦈😱"
    },
    "2023-05-31": {
        "id": "tt0137523",
        "emojiText": "🥊♣️🧼"
    },
    "2023-06-01": {
        "id": "tt0088247",
        "emojiText": "🤖🕶️🔫"
    },
    "2023-06-02": {
        "id": "tt0110413",
        "emojiText": "🧔🔫🥛👧"
    },
    "2023-06-03": {
        "id": "tt0110912",
        "emojiText": "💼🕺💉💃☕⌚"
    },
    "2023-06-04": {
        "id": "tt0083658",
        "emojiText": "🗡️🏃‍♂️"
    },
    "2023-06-05": {
        "id": "tt4729430",
        "emojiText": "📫🧓🔨🧸🛷"
    },
    "2023-06-06": {
        "id": "tt2382320",
        "emojiText": "🚫🕐💀"
    },
    "2023-06-07": {
        "id": "tt1201607",
        "emojiText": "👦⚡☠️⚔️2️⃣"
    },
    "2023-06-08": {
        "id": "tt0095016",
        "emojiText": "🗼🎄👨🔫"
    },
    "2023-06-09": {
        "id": "tt0041959",
        "emojiText": "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2023-06-10": {
        "id": "tt0070047",
        "emojiText": "✝️🤮🙏"
    },
    "2023-06-11": {
        "id": "tt0266697",
        "emojiText": "👱‍♀️⛩️⚔️"
    },
    "2023-06-12": {
        "id": "tt0081505",
        "emojiText": "🪓😈🛀"
    },
    "2023-06-13": {
        "id": "tt10298810",
        "emojiText": "👨‍🚀🌌"
    },
    "2023-06-14": {
        "id": "tt0167404",
        "emojiText": "👁️👀💀🧑‍🤝‍🧑"
    },
    "2023-06-15": {
        "id": "tt9032400",
        "emojiText": "🦸‍♂️🌍♾️"
    },
    "2023-06-16": {
        "id": "tt0317705",
        "emojiText": "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2023-06-17": {
        "id": "tt6751668",
        "emojiText": "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2023-06-18": {
        "id": "tt0266543",
        "emojiText": "🔎🐟"
    },
    "2023-06-19": {
        "id": "tt0075148",
        "emojiText": "🥊"
    },
    "2023-06-20": {
        "id": "tt0112573",
        "emojiText": "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2023-06-21": {
        "id": "tt0816692",
        "emojiText": "🚀🌊⏲️🌽"
    },
    "2023-06-22": {
        "id": "tt0102926",
        "emojiText": "🤐🐑"
    },
    "2023-06-23": {
        "id": "tt0884328",
        "emojiText": "🌫️🛒🌫️"
    },
    "2023-06-24": {
        "id": "tt1375666",
        "emojiText": "💤🌀⤴️"
    },
    "2023-06-25": {
        "id": "tt0468569",
        "emojiText": "🦸‍♂️🌃🃏🦇"
    },
    "2023-06-26": {
        "id": "tt0071562",
        "emojiText": "🕴️🇮🇹2️⃣🔫🐴"
    },
    "2023-06-27": {
        "id": "tt0120737",
        "emojiText": "💍🏔️🧝‍♂️🧙‍♂️🐉"
    },
    "2023-06-28": {
        "id": "tt0167261",
        "emojiText": "💍2️⃣🏞️🏰🔥"
    },
    "2023-06-29": {
        "id": "tt0080684",
        "emojiText": "🚀⭐⚔️❄️🤖"
    },
    "2023-06-30": {
        "id": "tt0099685",
        "emojiText": "🇮🇹🔫💵🍝👨‍👩‍👦‍👦"
    },
    "2023-07-01": {
        "id": "tt0073486",
        "emojiText": "🦅🛏️🏥🚁🏞️"
    },
    "2023-07-02": {
        "id": "tt0047478",
        "emojiText": "🇯🇵⚔️🌾🏰🌄"
    },
    "2023-07-03": {
        "id": "tt0038650",
        "emojiText": "🏡💕👼🎄💸"
    },
    "2023-07-04": {
        "id": "tt0317248",
        "emojiText": "🌆🇧🇷🔫🏚️"
    },
    "2023-07-05": {
        "id": "tt0120815",
        "emojiText": "🇺🇸🔫🔎👨‍🦱"
    },
    "2023-07-06": {
        "id": "tt0118799",
        "emojiText": "👨‍👦🇮🇹😄🌹"
    },
    "2023-07-07": {
        "id": "tt0103064",
        "emojiText": "🤖🔫2️⃣💥"
    },
    "2023-07-08": {
        "id": "tt0407887",
        "emojiText": "👮‍♂️🔫🐀🇮🇪"
    },
    "2023-07-09": {
        "id": "tt1675434",
        "emojiText": "🇫🇷👨‍🦽👨‍🦱🎵"
    },
    "2023-07-10": {
        "id": "tt0027977",
        "emojiText": "⚙️🏭🕰️😄"
    },
    "2023-07-11": {
        "id": "tt0056058",
        "emojiText": "🇯🇵🗡️🎎🏯"
    },
    "2023-07-12": {
        "id": "tt0064116",
        "emojiText": "🤠🏜️🚂🔫"
    },
    "2023-07-13": {
        "id": "tt0047396",
        "emojiText": "👀🔭🏢🚔"
    },
    "2023-07-14": {
        "id": "tt0078748",
        "emojiText": "👽🚀🏭🌌"
    },
    "2023-07-15": {
        "id": "tt0095765",
        "emojiText": "🎥🎞️🇮🇹💔"
    },
    "2023-07-16": {
        "id": "tt0209144",
        "emojiText": "⏮️🧩🔍📸"
    },
    "2023-07-17": {
        "id": "tt0405094",
        "emojiText": "🇩🇪👀🎧📝"
    },
    "2023-07-18": {
        "id": "tt0043014",
        "emojiText": "🌅🛣️🎥👩‍🦳"
    },
    "2023-07-19": {
        "id": "tt0050825",
        "emojiText": "🥾🏞️🎖️🇫🇷"
    },
    "2023-07-20": {
        "id": "tt0032553",
        "emojiText": "🌍🎩👨‍⚖️🎈"
    },
    "2023-07-21": {
        "id": "tt0051201",
        "emojiText": "🧐👩‍⚖️🕵️‍♂️📝"
    },
    "2023-07-22": {
        "id": "tt0036775",
        "emojiText": "🔄🔍💰👫"
    },
    "2023-07-23": {
        "id": "tt0053604",
        "emojiText": "🏢💘👨‍💼"
    },
    "2023-07-24": {
        "id": "tt0086250",
        "emojiText": "👨💊🔫🏙️"
    },
    "2023-07-25": {
        "id": "tt0044741",
        "emojiText": "👨🏻‍💼⏳🎢"
    },
    "2023-07-26": {
        "id": "tt0070735",
        "emojiText": "🎩💰🃏"
    },
    "2023-07-27": {
        "id": "tt0056592",
        "emojiText": "🕊️⚖️👧👦"
    },
    "2023-07-28": {
        "id": "tt0119488",
        "emojiText": "🔎🚔🚬🌆"
    },
    "2023-07-29": {
        "id": "tt1832382",
        "emojiText": "👫🏻💔🇮🇷"
    },
    "2023-07-30": {
        "id": "tt0208092",
        "emojiText": "💎🥊🐷"
    },
    "2023-07-31": {
        "id": "tt1255953",
        "emojiText": "🔥👩‍👧‍👦📜"
    },
    "2023-08-01": {
        "id": "tt0097576",
        "emojiText": "🤠🏺✝️"
    },
    "2023-08-02": {
        "id": "tt0040522",
        "emojiText": "🚲❌👨‍👦"
    },
    "2023-08-03": {
        "id": "tt8579674",
        "emojiText": "🌍⏳🔫🕊️1️⃣7️⃣"
    },
    "2023-08-04": {
        "id": "tt0986264",
        "emojiText": "👦📚🌟"
    },
    "2023-08-05": {
        "id": "tt0363163",
        "emojiText": "🇩🇪👨‍✈️⬇️"
    },
    "2023-08-06": {
        "id": "tt10872600",
        "emojiText": "🕷️👦🚫🏠"
    },
    "2023-08-07": {
        "id": "tt0059578",
        "emojiText": "💰💰🔫"
    },
    "2023-08-08": {
        "id": "tt0372784",
        "emojiText": "🦇👦🏙️"
    },
    "2023-08-09": {
        "id": "tt5074352",
        "emojiText": "🤼‍♀️🏅🇮🇳"
    },
    "2023-08-10": {
        "id": "tt0012349",
        "emojiText": "🧒🎩👫"
    },
    "2023-08-11": {
        "id": "tt10272386",
        "emojiText": "👴🧠🔄"
    },
    "2023-08-12": {
        "id": "tt0042192",
        "emojiText": "🎭👩🔝"
    },
    "2023-08-13": {
        "id": "tt6966692",
        "emojiText": "🚗🎵📗"
    },
    "2023-08-14": {
        "id": "tt0055031",
        "emojiText": "⚖️🇩🇪🕰️"
    },
    "2023-08-15": {
        "id": "tt0042876",
        "emojiText": "🌳🔪🎌🎭"
    },
    "2023-08-16": {
        "id": "tt0477348",
        "emojiText": "🏜️💼💰🔫"
    },
    "2023-08-17": {
        "id": "tt0080678",
        "emojiText": "🎩🐘😭💔"
    },
    "2023-08-18": {
        "id": "tt0071315",
        "emojiText": "🕵️🏙️💔🔪"
    },
    "2023-08-19": {
        "id": "tt0031381",
        "emojiText": "👩💔🌪️🏡"
    },
    "2023-08-20": {
        "id": "tt0434409",
        "emojiText": "✌️🎭💣🇬🇧"
    },
    "2023-08-21": {
        "id": "tt0084787",
        "emojiText": "❄️👾😱🔥"
    },
    "2023-08-22": {
        "id": "tt0120735",
        "emojiText": "🔒💼🔫💰"
    },
    "2023-08-23": {
        "id": "tt0046912",
        "emojiText": "☎️🔪😱🕵️"
    },
    "2023-08-24": {
        "id": "tt1305806",
        "emojiText": "👁️🔍💔⏳"
    },
    "2023-08-25": {
        "id": "tt0050212",
        "emojiText": "🌉🏞️⛓️💣"
    },
    "2023-08-26": {
        "id": "tt0347149",
        "emojiText": "🧙‍♂️🏰🔮🐺"
    },
    "2023-08-27": {
        "id": "tt5027774",
        "emojiText": "3️⃣🛑🔥💔"
    },
    "2023-08-28": {
        "id": "tt1205489",
        "emojiText": "🚗👴🔫🏠"
    },
    "2023-08-29": {
        "id": "tt1291584",
        "emojiText": "🤼‍♂️🥊👨‍👦‍👦🏆"
    },
    "2023-08-30": {
        "id": "tt0116282",
        "emojiText": "❄️🚗💵🔫"
    },
    "2023-08-31": {
        "id": "tt1392214",
        "emojiText": "👭🚐👮‍♂️🔍"
    },
    "2023-09-01": {
        "id": "tt0405159",
        "emojiText": "🥊💵🏆👧"
    },
    "2023-09-02": {
        "id": "tt0015864",
        "emojiText": "⛏️🏔️🤑🍞"
    },
    "2023-09-03": {
        "id": "tt0047296",
        "emojiText": "🚢⚓️🥊🐦"
    },
    "2023-09-04": {
        "id": "tt0118849",
        "emojiText": "🧒👟🏃‍♂️🏅"
    },
    "2023-09-05": {
        "id": "tt0052618",
        "emojiText": "👑🐎🛡️⛓️"
    },
    "2023-09-06": {
        "id": "tt0017925",
        "emojiText": "🚂👨‍✈️💣💔"
    },
    "2023-09-07": {
        "id": "tt0112471",
        "emojiText": "👫🌇💬❤️"
    },
    "2023-09-08": {
        "id": "tt0050986",
        "emojiText": "🍓👴🚗💭"
    },
    "2023-09-09": {
        "id": "tt2267998",
        "emojiText": "👩💍💌🔪"
    },
    "2023-09-10": {
        "id": "tt0077416",
        "emojiText": "🦌🏹🔫🇻🇳"
    },
    "2023-09-11": {
        "id": "tt0107207",
        "emojiText": "👨‍👦💣⚖️🇬🇧"
    },
    "2023-09-12": {
        "id": "tt3011894",
        "emojiText": "🦊📖💥💔"
    },
    "2023-09-13": {
        "id": "tt1392190",
        "emojiText": "😡🚗💥🏜️"
    },
    "2023-09-14": {
        "id": "tt0978762",
        "emojiText": "👧💌👴🇦🇺"
    },
    "2023-09-15": {
        "id": "tt0019254",
        "emojiText": "👩‍🌾🙏🔥⛪"
    },
    "2023-09-16": {
        "id": "tt0097165",
        "emojiText": "👨‍🏫📚💭🕊️"
    },
    "2023-09-17": {
        "id": "tt0395169",
        "emojiText": "🏨🇷🇼👨‍👩‍👧‍👦🔫"
    },
    "2023-09-18": {
        "id": "tt0046438",
        "emojiText": "👵👴🌆🎌"
    },
    "2023-09-19": {
        "id": "tt0048473",
        "emojiText": "👨‍👩‍👧‍👦🏞️🇮🇳💔"
    },
    "2023-09-20": {
        "id": "tt0091763",
        "emojiText": "👨‍👦‍👦🔫🌴🇻🇳"
    },
    "2023-09-21": {
        "id": "tt1950186",
        "emojiText": "🚘⚙️🏁🏆"
    },
    "2023-09-22": {
        "id": "tt0092005",
        "emojiText": "👦👦👦👦🛤️👑"
    },
    "2023-09-23": {
        "id": "tt1895587",
        "emojiText": "🔍📰⛪👥"
    },
    "2023-09-24": {
        "id": "tt1979320",
        "emojiText": "🏎️💨⏱️🏆"
    },
    "2023-09-25": {
        "id": "tt0074958",
        "emojiText": "📺💬🏙️🎭"
    },
    "2023-09-26": {
        "id": "tt0758758",
        "emojiText": "🚶‍♂️🌲🚌🌄"
    },
    "2023-09-27": {
        "id": "tt3315342",
        "emojiText": "👨🔪🚗👧"
    },
    "2023-09-28": {
        "id": "tt0381681",
        "emojiText": "💑🌅🇫🇷💬"
    },
    "2023-09-29": {
        "id": "tt0036868",
        "emojiText": "🇺🇸🎖️👨‍👩‍👧‍👦❤️"
    },
    "2023-09-30": {
        "id": "tt0032976",
        "emojiText": "👰🏰🔥💔"
    },
    "2023-10-01": {
        "id": "tt0058946",
        "emojiText": "🇩🇿💣🔫🏙️"
    },
    "2023-10-02": {
        "id": "tt0032551",
        "emojiText": "🍇😡👨‍👩‍👧‍👦🚚"
    },
    "2023-10-03": {
        "id": "tt0061512",
        "emojiText": "😎✋👷🥚"
    },
    "2023-10-04": {
        "id": "tt0245712",
        "emojiText": "🐶❤️🚗💥"
    },
    "2023-10-05": {
        "id": "tt0113247",
        "emojiText": "👨‍👨‍👦🏙️🔫💔"
    },
    "2023-10-06": {
        "id": "tt0053198",
        "emojiText": "👦💔🏃‍♂️🇫🇷"
    },
    "2023-10-07": {
        "id": "tt0060827",
        "emojiText": "👩‍⚕️👩🎭🏖️"
    },
    "2023-10-08": {
        "id": "tt0476735",
        "emojiText": "👨👦❤️💔"
    },
    "2023-10-09": {
        "id": "tt0079470",
        "emojiText": "👨‍🦱🙌✨😂"
    },
    "2023-10-10": {
        "id": "tt0025316",
        "emojiText": "🌃👫🚌💘"
    },
    "2023-10-11": {
        "id": "tt0059742",
        "emojiText": "🎵🏞️👩‍👧‍👦🇦🇹"
    },
    "2023-10-12": {
        "id": "tt0071411",
        "emojiText": "🧑🏔️🌲🇷🇺"
    },
    "2023-10-13": {
        "id": "tt4016934",
        "emojiText": "👩‍❤️‍👩📘🇰🇷🏰"
    },
    "2023-10-14": {
        "id": "tt15097216",
        "emojiText": "⚖️🇮🇳💼🗣️"
    },
    "2023-10-15": {
        "id": "tt0083987",
        "emojiText": "🇮🇳👳‍♂️☮️📚"
    },
    "2023-10-16": {
        "id": "tt1454029",
        "emojiText": "👩‍🦰📚👩‍🦱🥧"
    },
    "2023-10-17": {
        "id": "tt3704428",
        "emojiText": "🕺🎤🎸🇺🇸"
    },
    "2023-10-18": {
        "id": "tt5113044",
        "emojiText": "🍌🦹‍♂️🏢🌆"
    },
    "2023-10-19": {
        "id": "tt10304142",
        "emojiText": "🚲🌷🇳🇱🏛️"
    },
    "2023-10-20": {
        "id": "tt9419884",
        "emojiText": "🧙‍♂️💫🌀🌌"
    },
    "2023-10-21": {
        "id": "tt7144666",
        "emojiText": "📞⚫🕵️‍♂️👦"
    },
    "2023-10-22": {
        "id": "tt8041270",
        "emojiText": "🦖🌍🏰🦕"
    },
    "2023-10-23": {
        "id": "tt4123432",
        "emojiText": "🔮🐉🧙📜"
    },
    "2023-10-24": {
        "id": "tt21059520",
        "emojiText": "👧🖼️📷🔍"
    },
    "2023-10-25": {
        "id": "tt13841850",
        "emojiText": "👨👨‍🦱👨‍🦳🕴️"
    },
    "2023-10-26": {
        "id": "tt8093700",
        "emojiText": "👩👑⚔️🏰"
    },
    "2023-10-27": {
        "id": "tt7131622",
        "emojiText": "🎬🌟🌴💫"
    },
    "2023-10-28": {
        "id": "tt13320622",
        "emojiText": "🗺️🏙️🔍🕵️‍♂️"
    },
    "2023-10-29": {
        "id": "tt12593682",
        "emojiText": "🚄🔫💥🎯"
    },
    "2023-10-30": {
        "id": "tt14549466",
        "emojiText": "🔮🚀🔬⚖️"
    },
    "2023-10-31": {
        "id": "tt5108870",
        "emojiText": "🩸🦇🔬🧛‍♂️"
    },
    "2023-11-01": {
        "id": "tt1649418",
        "emojiText": "👤🔫🕵️‍♂️💼"
    },
    "2023-11-02": {
        "id": "tt11128440",
        "emojiText": "👔💼🛍️🗣️"
    },
    "2023-11-03": {
        "id": "tt8178634",
        "emojiText": "🇮🇳🔥⚔️🛤️"
    },
    "2023-11-04": {
        "id": "tt9851854",
        "emojiText": "👮‍♂️🎖️🇮🇳🔫"
    },
    "2023-11-05": {
        "id": "tt2231253",
        "emojiText": "🃏🎲🔫💰"
    },
    "2023-11-06": {
        "id": "tt4998632",
        "emojiText": "🚑🚨🏥🚔"
    },
    "2023-11-07": {
        "id": "tt1323594",
        "emojiText": "😈👧👧👧🍌"
    },
    "2023-11-08": {
        "id": "tt14376344",
        "emojiText": "💃🕺🎶🎈"
    },
    "2023-11-09": {
        "id": "tt8323668",
        "emojiText": "👴👵💰🎰"
    },
    "2023-11-10": {
        "id": "tt0089218",
        "emojiText": "🏴‍☠️🗺️💎👦"
    },
    "2023-11-11": {
        "id": "tt4181158",
        "emojiText": "👋👋💔💌"
    },
    "2023-11-12": {
        "id": "tt1981115",
        "emojiText": "🔨⚡🌌👑"
    },
    "2023-11-13": {
        "id": "tt14145426",
        "emojiText": "👦👦🚀🌌"
    },
    "2023-11-14": {
        "id": "tt0134119",
        "emojiText": "🎩🔎💼🔪"
    },
    "2023-11-15": {
        "id": "tt1879016",
        "emojiText": "💼🕵️‍♂️📜🕹️"
    },
    "2023-11-16": {
        "id": "tt10954652",
        "emojiText": "👵🏝️⏳👴"
    },
    "2023-11-17": {
        "id": "tt12545566",
        "emojiText": "📜🎩💕💌"
    },
    "2024-08-27": {
        "id": "tt0114709",
        "emojiText": "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2024-08-28": {
        "id": "tt0167260",
        "emojiText": "💍🌋🧙‍♂️👑"
    },
    "2024-08-29": {
        "id": "tt0103639",
        "emojiText": "🧞‍♂️🪔🐒👸🤴"
    },
    "2024-08-30": {
        "id": "tt0211915",
        "emojiText": "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2024-08-31": {
        "id": "tt1745960",
        "emojiText": "✈️🇺🇸🕶️"
    },
    "2024-09-01": {
        "id": "tt0109830",
        "emojiText": "🏃🍫🦐"
    },
    "2024-09-02": {
        "id": "tt0120382",
        "emojiText": "🙍‍♂️🎥⛵😨📺"
    },
    "2024-09-03": {
        "id": "tt4633694",
        "emojiText": "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2024-09-04": {
        "id": "tt0090605",
        "emojiText": "👽🤰🩸😱"
    },
    "2024-09-05": {
        "id": "tt0062622",
        "emojiText": "👁️🖥️🛰️🚀"
    },
    "2024-09-06": {
        "id": "tt0054215",
        "emojiText": "🔪🚿🧓"
    },
    "2024-09-07": {
        "id": "tt0101414",
        "emojiText": "🌹👸🧌"
    },
    "2024-09-08": {
        "id": "tt0264464",
        "emojiText": "🏃‍♂️✈️💰🏃"
    },
    "2024-09-09": {
        "id": "tt0045152",
        "emojiText": "☔🎤😃"
    },
    "2024-09-10": {
        "id": "tt0107290",
        "emojiText": "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2024-09-11": {
        "id": "tt0993846",
        "emojiText": "🐺🧱🛣️〽️🏦💵"
    },
    "2024-09-12": {
        "id": "tt0382932",
        "emojiText": "🐭💆‍♂️👨‍🍳🍲"
    },
    "2024-09-13": {
        "id": "tt10648342",
        "emojiText": "🦸🔨❤️➕⛈️"
    },
    "2024-09-14": {
        "id": "tt0482571",
        "emojiText": "🏃🚪🎩🚪🏃▶️👬"
    },
    "2024-09-15": {
        "id": "tt2380307",
        "emojiText": "🎸💀👦🇲🇽"
    },
    "2024-09-16": {
        "id": "tt0107048",
        "emojiText": "🔄⏰🦔"
    },
    "2024-09-17": {
        "id": "tt0133093",
        "emojiText": "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2024-09-18": {
        "id": "tt1130884",
        "emojiText": "👮‍♂️💊🏝️😨"
    },
    "2024-09-19": {
        "id": "tt2293640",
        "emojiText": "🤓🤓🤓🍌🧒"
    },
    "2024-09-20": {
        "id": "tt0114369",
        "emojiText": "😋😛😴🤢😡😎🤤👀"
    },
    "2024-09-21": {
        "id": "tt5311514",
        "emojiText": "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2024-09-22": {
        "id": "tt0119217",
        "emojiText": "🧹🏫👨‍🏫🧠🍎"
    },
    "2024-09-23": {
        "id": "tt12412888",
        "emojiText": "🦔👟✌️"
    },
    "2024-09-24": {
        "id": "tt0088763",
        "emojiText": "🚗🔙⌚👨‍🔬🙍"
    },
    "2024-09-25": {
        "id": "tt0435761",
        "emojiText": "👨‍🚀🤠🏫🍓🧸"
    },
    "2024-09-26": {
        "id": "tt0116629",
        "emojiText": "🇺🇸🎆👽👊"
    },
    "2024-09-27": {
        "id": "tt0325980",
        "emojiText": "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2024-09-28": {
        "id": "tt6467266",
        "emojiText": "🐷🦍🦁🎹🎧🦔"
    },
    "2024-09-29": {
        "id": "tt1160419",
        "emojiText": "👩‍👦🗡️⏳🐛🪐"
    },
    "2024-09-30": {
        "id": "tt0066921",
        "emojiText": "👁️🎩🦯🥛🍊"
    },
    "2024-10-01": {
        "id": "tt1049413",
        "emojiText": "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2024-10-02": {
        "id": "tt2582802",
        "emojiText": "🥁👨‍🦲⏱️🩸🚗📁🎶"
    },
    "2024-10-03": {
        "id": "tt0120338",
        "emojiText": "💑🚢🧊🥶👵"
    },
    "2024-10-04": {
        "id": "tt2096673",
        "emojiText": "😡😭😊🤢😱🧠"
    },
    "2024-10-05": {
        "id": "tt0050083",
        "emojiText": "😠1️⃣2️⃣"
    },
    "2024-10-06": {
        "id": "tt1396484",
        "emojiText": "🤡👿🎈"
    },
    "2024-10-07": {
        "id": "tt0073195",
        "emojiText": "🦈😱"
    },
    "2024-10-08": {
        "id": "tt0137523",
        "emojiText": "🥊♣️🧼"
    },
    "2024-10-09": {
        "id": "tt1877830",
        "emojiText": "🦇🐱🐧❓"
    },
    "2024-10-10": {
        "id": "tt0088247",
        "emojiText": "🤖🕶️🔫"
    },
    "2024-10-11": {
        "id": "tt0110413",
        "emojiText": "🧔🔫🥛👧"
    },
    "2024-10-12": {
        "id": "tt0110912",
        "emojiText": "💼🕺💉💃☕⌚"
    },
    "2024-10-13": {
        "id": "tt0083658",
        "emojiText": "🗡️🏃‍♂️"
    },
    "2024-10-14": {
        "id": "tt4729430",
        "emojiText": "📫🧓🔨🧸🛷"
    },
    "2024-10-15": {
        "id": "tt2382320",
        "emojiText": "🚫🕐💀"
    },
    "2024-10-16": {
        "id": "tt1201607",
        "emojiText": "👦⚡☠️⚔️2️⃣"
    },
    "2024-10-17": {
        "id": "tt2024544",
        "emojiText": "🕛🗓️⛓️"
    },
    "2024-10-18": {
        "id": "tt0095016",
        "emojiText": "🗼🎄👨🔫"
    },
    "2024-10-19": {
        "id": "tt0041959",
        "emojiText": "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2024-10-20": {
        "id": "tt0070047",
        "emojiText": "✝️🤮🙏"
    },
    "2024-10-21": {
        "id": "tt0266697",
        "emojiText": "👱‍♀️⛩️⚔️"
    },
    "2024-10-22": {
        "id": "tt0081505",
        "emojiText": "🪓😈🛀"
    },
    "2024-10-23": {
        "id": "tt10298810",
        "emojiText": "👨‍🚀🌌"
    },
    "2024-10-24": {
        "id": "tt0167404",
        "emojiText": "👁️👀💀🧑‍🤝‍🧑"
    },
    "2024-10-25": {
        "id": "tt9032400",
        "emojiText": "🦸‍♂️🌍♾️"
    },
    "2024-10-26": {
        "id": "tt0317705",
        "emojiText": "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2024-10-27": {
        "id": "tt6751668",
        "emojiText": "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2024-10-28": {
        "id": "tt0266543",
        "emojiText": "🔎🐟"
    },
    "2024-10-29": {
        "id": "tt4154756",
        "emojiText": "♾️⚔️"
    },
    "2024-10-30": {
        "id": "tt0075148",
        "emojiText": "🥊"
    },
    "2024-10-31": {
        "id": "tt0112573",
        "emojiText": "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2024-11-01": {
        "id": "tt0816692",
        "emojiText": "🚀🌊⏲️🌽"
    },
    "2024-11-02": {
        "id": "tt0102926",
        "emojiText": "🤐🐑"
    },
    "2024-11-03": {
        "id": "tt0032138",
        "emojiText": "🐶👠👠🌪️🏠"
    },
    "2024-11-04": {
        "id": "tt0884328",
        "emojiText": "🌫️🛒🌫️"
    },
    "2024-11-05": {
        "id": "tt1375666",
        "emojiText": "💤🌀⤴️"
    },
    "2024-11-06": {
        "id": "tt0060196",
        "emojiText": "👍👎👹"
    },
    "2024-11-07": {
        "id": "tt0198781",
        "emojiText": "🟢👁️🟢👧👹"
    },
    "2024-11-08": {
        "id": "tt0892769",
        "emojiText": "⚫🐉🦷"
    },
    "2024-11-09": {
        "id": "tt0910970",
        "emojiText": "🌍🗑️🤖🌱❤️"
    },
    "2024-11-10": {
        "id": "tt8503618",
        "emojiText": "🇺🇸🎼🗽🎵🔫"
    },
    "2024-11-11": {
        "id": "tt0457430",
        "emojiText": "👁️👐👁️"
    },
    "2024-11-12": {
        "id": "tt0118715",
        "emojiText": "🎳🥛💼🩲"
    },
    "2024-11-13": {
        "id": "tt0169547",
        "emojiText": "🇺🇸💅🌹🌹🌹🌹🌹🌹"
    },
    "2024-11-14": {
        "id": "tt0056172",
        "emojiText": "🏇🏜️🗡️"
    },
    "2024-11-15": {
        "id": "tt1345836",
        "emojiText": "🦇🤵⬆️"
    },
    "2024-11-16": {
        "id": "tt0102926",
        "emojiText": "🔇🐏"
    },
    "2024-11-17": {
        "id": "tt0034583",
        "emojiText": "🇫🇷💖🚂😒🕌☕🎹✈️"
    },
    "2024-11-18": {
        "id": "tt1028532",
        "emojiText": "🐶🙍‍♂️🚋⏱️"
    },
    "2024-11-19": {
        "id": "tt0119698",
        "emojiText": "🐺👸🩸🐗🐛"
    },
    "2024-11-20": {
        "id": "tt0090605",
        "emojiText": "🌐👩👧🐱💥👽"
    },
    "2024-11-21": {
        "id": "tt0053291",
        "emojiText": "👨‍🦰👨‍🦱👠💄👗"
    },
    "2024-11-22": {
        "id": "tt0110357",
        "emojiText": "🦁🐆🐗👑"
    },
    "2024-11-23": {
        "id": "tt0245429",
        "emojiText": "🐉😱👹👵⛩️"
    },
    "2024-11-24": {
        "id": "tt0117951",
        "emojiText": "💉👬🧑‍🤝‍🧑💊🇬🇧🤪🤮"
    },
    "2024-11-25": {
        "id": "tt7657566",
        "emojiText": "👨🔎🇪🇬⛴️☠️"
    },
    "2024-11-26": {
        "id": "tt0096283",
        "emojiText": "▶️🚪🏠🐻"
    },
    "2024-11-27": {
        "id": "tt4513678",
        "emojiText": "🙍🙍🙍🙍👻🧹"
    },
    "2024-11-28": {
        "id": "tt1464335",
        "emojiText": "🗺️🧗‍♂️🚁💰"
    },
    "2024-11-29": {
        "id": "tt0338013",
        "emojiText": "💑💔😭💵👨‍🔬😴🧠💻🕵️🙂"
    },
    "2024-11-30": {
        "id": "tt0068646",
        "emojiText": "🐴🛏️🩸"
    },
    "2024-12-01": {
        "id": "tt0082971",
        "emojiText": "🏜️🕌💼💀🤠"
    },
    "2024-12-02": {
        "id": "tt6264654",
        "emojiText": "🎮🔫🧑‍💻⌨️🏦"
    },
    "2024-12-03": {
        "id": "tt0017136",
        "emojiText": "🤖🌆🇩🇪🎥"
    },
    "2024-12-04": {
        "id": "tt0120689",
        "emojiText": "💀🚶‍♂️🟩🟩🟩"
    },
    "2024-12-05": {
        "id": "tt0111161",
        "emojiText": "👨‍⚖️😧♟️⛏️👋"
    },
    "2024-12-06": {
        "id": "tt0172495",
        "emojiText": "🤼‍♂️🏟️⚔️👎"
    },
    "2024-12-07": {
        "id": "tt1201607",
        "emojiText": "👦⚡🔺💫🚫👃☠️"
    },
    "2024-12-08": {
        "id": "tt8772262",
        "emojiText": "💐🔪🐻"
    },
    "2024-12-09": {
        "id": "tt1853728",
        "emojiText": "🦷🤠🧐🏇⛓️"
    },
    "2024-12-10": {
        "id": "tt0114814",
        "emojiText": "👮‍♂️🤬🔫🕵️‍♂️❔"
    },
    "2024-12-11": {
        "id": "tt0095327",
        "emojiText": "👨‍👧⚰️🌌🌂🇯🇵"
    },
    "2024-12-12": {
        "id": "tt0053198",
        "emojiText": "👦💔🏃‍♂️🇫🇷"
    },
    "2024-12-13": {
        "id": "tt7286456",
        "emojiText": "🔫🤡🃏"
    },
    "2024-12-14": {
        "id": "tt0253474",
        "emojiText": "🧑🎹"
    },
    "2024-12-15": {
        "id": "tt11703710",
        "emojiText": "🎩👒📽️🎙️🇬🇧"
    },
    "2024-12-16": {
        "id": "tt0057115",
        "emojiText": "🇩🇪🕳️⛏️🏍️🚞"
    },
    "2024-12-17": {
        "id": "tt1130884",
        "emojiText": "🏝️🧐🏨"
    },
    "2024-12-18": {
        "id": "tt0100157",
        "emojiText": "⌨️👩‍🦼🔨👩‍🦰"
    },
    "2024-12-19": {
        "id": "tt15521050",
        "emojiText": "❤️🍦"
    },
    "2024-12-20": {
        "id": "tt0076759",
        "emojiText": "⭐🔫💣"
    },
    "2024-12-21": {
        "id": "tt0081398",
        "emojiText": "😡🐂"
    },
    "2024-12-22": {
        "id": "tt0092099",
        "emojiText": "🔝🔫🛩"
    },
    "2024-12-23": {
        "id": "tt0105236",
        "emojiText": "🚧🌊🚧🐶"
    },
    "2024-12-24": {
        "id": "tt1050263",
        "emojiText": "❄◽"
    },
    "2024-12-25": {
        "id": "tt0106965",
        "emojiText": "🆓🐳"
    },
    "2024-12-26": {
        "id": "tt0397892",
        "emojiText": "🐶⚡"
    },
    "2024-12-27": {
        "id": "tt11245972",
        "emojiText": "😱🔪"
    },
    "2024-12-28": {
        "id": "tt0118571",
        "emojiText": "🛩🇺🇸1"
    },
    "2024-12-29": {
        "id": "tt0119654",
        "emojiText": "👨🏻⤵⬛👽"
    },
    "2024-12-30": {
        "id": "tt0119695",
        "emojiText": "💰👄"
    },
    "2024-12-31": {
        "id": "tt0120632",
        "emojiText": "🏙👼🏻👼🏻"
    },
    "2025-01-01": {
        "id": "tt0120812",
        "emojiText": "🚗💨🕑"
    },
    "2025-01-02": {
        "id": "tt0250494",
        "emojiText": "⚖👩🏼👛🎥"
    },
    "2025-01-03": {
        "id": "tt0375679",
        "emojiText": "🚙💥🚗"
    },
    "2025-01-04": {
        "id": "tt0437405",
        "emojiText": "📄👨🏻"
    },
    "2025-01-05": {
        "id": "tt0417217",
        "emojiText": "2➡💰"
    },
    "2025-01-06": {
        "id": "tt0112431",
        "emojiText": "🐷💬🎥"
    },
    "2025-01-07": {
        "id": "tt0086034",
        "emojiText": "🐙🐱"
    },
    "2025-01-08": {
        "id": "tt6139732",
        "emojiText": "👳🏻🔮🌙"
    },
    "2025-01-09": {
        "id": "tt0053285",
        "emojiText": "💤👩🏻"
    },
    "2025-01-10": {
        "id": "tt0096244",
        "emojiText": "🍹🌄"
    },
    "2025-01-11": {
        "id": "tt0097239",
        "emojiText": "🚘👵🏻🌼"
    },
    "2025-01-12": {
        "id": "tt0253474",
        "emojiText": "👨🏻🎹"
    },
    "2025-01-13": {
        "id": "tt9347730",
        "emojiText": "🍬👨🏻"
    },
    "2025-01-14": {
        "id": "tt0061852",
        "emojiText": "🌴📖"
    },
    "2025-01-15": {
        "id": "tt0111257",
        "emojiText": "💣🚌💨💨"
    },
    "2025-01-16": {
        "id": "tt0113117",
        "emojiText": "🇫🇷💋"
    },
    "2025-01-17": {
        "id": "tt0039536",
        "emojiText": "💋💀"
    },
    "2025-01-18": {
        "id": "tt0050083",
        "emojiText": "12😡👨🏻"
    },
    "2025-01-19": {
        "id": "tt1602620",
        "emojiText": "👴🏻❤👵🏻"
    },
    "2025-01-20": {
        "id": "tt0133152",
        "emojiText": "🌍🐵🐵🐵"
    },
    "2025-01-21": {
        "id": "tt0120338",
        "emojiText": "🛳🌊💥"
    },
    "2025-01-22": {
        "id": "tt0157503",
        "emojiText": "💧💀👸🏻✨"
    },
    "2025-01-23": {
        "id": "tt0161081",
        "emojiText": "❓🛌🏻⬇"
    },
    "2025-01-24": {
        "id": "tt0163187",
        "emojiText": "🏃🏻👰🏻"
    },
    "2025-01-25": {
        "id": "tt0175142",
        "emojiText": "😱🎬"
    },
    "2025-01-26": {
        "id": "tt0448694",
        "emojiText": "😺👢"
    },
    "2025-01-27": {
        "id": "tt4593060",
        "emojiText": "👃🏻➡➡➡"
    },
    "2025-01-28": {
        "id": "tt0044706",
        "emojiText": "⬆🕛"
    },
    "2025-01-29": {
        "id": "tt0250687",
        "emojiText": "🐭🏃🏻🏃🏻🏃🏻"
    },
    "2025-01-30": {
        "id": "tt0259324",
        "emojiText": "👻👨🏻🐎"
    },
    "2025-01-31": {
        "id": "tt0259711",
        "emojiText": "🍦⛅"
    },
    "2025-02-01": {
        "id": "tt0265086",
        "emojiText": "⬛🐦⬇"
    },
    "2025-02-02": {
        "id": "tt1210819",
        "emojiText": "1👨🏻🐴"
    },
    "2025-02-03": {
        "id": "tt0960731",
        "emojiText": "😴📖"
    },
    "2025-02-04": {
        "id": "tt0358273",
        "emojiText": "🚶🏻➡➖➖"
    },
    "2025-02-05": {
        "id": "tt0360717",
        "emojiText": "👑🐵😡🏬"
    },
    "2025-02-06": {
        "id": "tt0366548",
        "emojiText": "😃👣"
    },
    "2025-02-07": {
        "id": "tt0371746",
        "emojiText": "ࠦ👨🏻"
    },
    "2025-02-08": {
        "id": "tt0377471",
        "emojiText": "🐝🆒"
    },
    "2025-02-09": {
        "id": "tt0389790",
        "emojiText": "🐝🎬"
    },
    "2025-02-10": {
        "id": "tt0396269",
        "emojiText": "👰🏻🤵🏻💥💥"
    },
    "2025-02-11": {
        "id": "tt0401792",
        "emojiText": "😈😈😈🌇"
    },
    "2025-02-12": {
        "id": "tt0409459",
        "emojiText": "⌚👨🏽👨🏽"
    },
    "2025-02-13": {
        "id": "tt0415778",
        "emojiText": "🔚🎮"
    },
    "2025-02-14": {
        "id": "tt0417148",
        "emojiText": "🐍🐍🛩"
    },
    "2025-02-15": {
        "id": "tt0441773",
        "emojiText": "👊🏻💥🐼"
    },
    "2025-02-16": {
        "id": "tt0448157",
        "emojiText": "🖐🏻🐓"
    },
    "2025-02-17": {
        "id": "tt0450259",
        "emojiText": "💉💎"
    },
    "2025-02-18": {
        "id": "tt0454876",
        "emojiText": "👳🏻🚣🏻♂🐅"
    },
    "2025-02-19": {
        "id": "tt0472181",
        "emojiText": "🔵👴🏻👧🏻👦🏻"
    },
    "2025-02-20": {
        "id": "tt0499549",
        "emojiText": "🔵👽"
    },
    "2025-02-21": {
        "id": "tt0800369",
        "emojiText": "⚡🔨💪🏻"
    },
    "2025-02-22": {
        "id": "tt0879870",
        "emojiText": "🍜🙏🏻❤"
    },
    "2025-02-23": {
        "id": "tt0942385",
        "emojiText": "🌴🍹⚡☔"
    },
    "2025-02-24": {
        "id": "tt0961097",
        "emojiText": "👹➡🇫🇷🗼"
    },
    "2025-02-25": {
        "id": "tt0978621",
        "emojiText": "🏃🏻💥⬅🚂"
    },
    "2025-02-26": {
        "id": "tt1605783",
        "emojiText": "🌃🕛🇫🇷🗼"
    },
    "2025-02-27": {
        "id": "tt1074638",
        "emojiText": "⛅🍃"
    },
    "2025-02-28": {
        "id": "tt2103264",
        "emojiText": "👑🇨🇳"
    },
    "2025-03-01": {
        "id": "tt1276104",
        "emojiText": "👨🏻🔫👦🏻🔄"
    },
    "2025-03-02": {
        "id": "tt1289401",
        "emojiText": "👻👻🔫"
    },
    "2025-03-03": {
        "id": "tt1416801",
        "emojiText": "🔫➡🍀👨🏻"
    },
    "2025-03-04": {
        "id": "tt1424381",
        "emojiText": "🐆🐅🐊"
    },
    "2025-03-05": {
        "id": "tt14403842",
        "emojiText": "🔟☔"
    },
    "2025-03-06": {
        "id": "tt1860353",
        "emojiText": "🐌💨"
    },
    "2025-03-07": {
        "id": "tt1637725",
        "emojiText": "🐻❤🍻🚬"
    },
    "2025-03-08": {
        "id": "tt1790885",
        "emojiText": "0🌌30"
    },
    "2025-03-09": {
        "id": "tt1798709",
        "emojiText": "👨🏻👓❤📱"
    },
    "2025-03-10": {
        "id": "tt1885300",
        "emojiText": "👌🏻👨🏻⬇"
    },
    "2025-03-11": {
        "id": "tt0054215",
        "emojiText": "🔪🚿"
    },
    "2025-03-12": {
        "id": "tt1981677",
        "emojiText": "🎤🎼💯"
    },
    "2025-03-13": {
        "id": "tt0099785",
        "emojiText": "🏠😱🎄👱🏻"
    },
    "2025-03-14": {
        "id": "tt2334879",
        "emojiText": "◽🏠⬇"
    },
    "2025-03-15": {
        "id": "tt0021749",
        "emojiText": "🏙💡💡"
    },
    "2025-03-16": {
        "id": "tt0105236",
        "emojiText": "🚧🌊🚧🐶"
    },
    "2025-03-17": {
        "id": "tt1049413",
        "emojiText": "🏠🎈🎈🎈"
    },
    "2025-03-18": {
        "id": "tt0068646",
        "emojiText": "🇮🇹😇👴🏻"
    },
    "2025-03-19": {
        "id": "tt0109830",
        "emojiText": "🏃🏻🍫🍤"
    },
    "2025-03-20": {
        "id": "tt0137523",
        "emojiText": "👊🏻♣"
    },
    "2025-03-21": {
        "id": "tt0266543",
        "emojiText": "🔎🐠"
    },
    "2025-03-22": {
        "id": "tt1375666",
        "emojiText": "💡➡😴"
    },
    "2025-03-23": {
        "id": "tt0114709",
        "emojiText": "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2025-03-24": {
        "id": "tt0167260",
        "emojiText": "💍🌋🧙‍♂️👑"
    },
    "2025-03-25": {
        "id": "tt6139732",
        "emojiText": "🧞‍♂️🪔🐒👸🤴"
    },
    "2025-03-26": {
        "id": "tt0211915",
        "emojiText": "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2025-03-27": {
        "id": "tt1745960",
        "emojiText": "✈️🇺🇸🕶️"
    },
    "2025-03-28": {
        "id": "tt0109830",
        "emojiText": "🏃🍫🦐"
    },
    "2025-03-29": {
        "id": "tt0120382",
        "emojiText": "🙍‍♂️🎥⛵😨📺"
    },
    "2025-03-30": {
        "id": "tt4633694",
        "emojiText": "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2025-03-31": {
        "id": "tt0090605",
        "emojiText": "👽🤰🩸😱"
    },
    "2025-04-01": {
        "id": "tt0062622",
        "emojiText": "👁️🖥️🛰️🚀"
    },
    "2025-04-02": {
        "id": "tt0054215",
        "emojiText": "🔪🚿🧓"
    },
    "2025-04-03": {
        "id": "tt0101414",
        "emojiText": "🌹👸🧌"
    },
    "2025-04-04": {
        "id": "tt0264464",
        "emojiText": "🏃‍♂️✈️💰🏃"
    },
    "2025-04-05": {
        "id": "tt0045152",
        "emojiText": "☔🎤😃"
    },
    "2025-04-06": {
        "id": "tt0107290",
        "emojiText": "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2025-04-07": {
        "id": "tt0382932",
        "emojiText": "🐭💆‍♂️👨‍🍳🍲"
    },
    "2025-04-08": {
        "id": "tt10648342",
        "emojiText": "🦸🔨❤️➕⛈️"
    },
    "2025-04-09": {
        "id": "tt0482571",
        "emojiText": "🏃🚪🎩🚪🏃▶️👬"
    },
    "2025-04-10": {
        "id": "tt2380307",
        "emojiText": "🎸💀👦🇲🇽"
    },
    "2025-04-11": {
        "id": "tt0107048",
        "emojiText": "🔄⏰🦔"
    },
    "2025-04-12": {
        "id": "tt0133093",
        "emojiText": "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2025-04-13": {
        "id": "tt1130884",
        "emojiText": "👮‍♂️💊🏝️😨"
    },
    "2025-04-14": {
        "id": "tt0114369",
        "emojiText": "😋😛😴🤢😡😎🤤👀"
    },
    "2025-04-15": {
        "id": "tt5311514",
        "emojiText": "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2025-04-16": {
        "id": "tt0119217",
        "emojiText": "🧹🏫👨‍🏫🧠🍎"
    },
    "2025-04-17": {
        "id": "tt12412888",
        "emojiText": "🦔👟✌️"
    },
    "2025-04-18": {
        "id": "tt0088763",
        "emojiText": "🚗🔙⌚👨‍🔬🙍"
    },
    "2025-04-19": {
        "id": "tt0435761",
        "emojiText": "👨‍🚀🤠🏫🍓🧸"
    },
    "2025-04-20": {
        "id": "tt0116629",
        "emojiText": "🇺🇸🎆👽👊"
    },
    "2025-04-21": {
        "id": "tt0325980",
        "emojiText": "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2025-04-22": {
        "id": "tt6467266",
        "emojiText": "🐷🦍🦁🎹🎧🦔"
    },
    "2025-04-23": {
        "id": "tt1160419",
        "emojiText": "👩‍👦🗡️⏳🐛🪐"
    },
    "2025-04-24": {
        "id": "tt0066921",
        "emojiText": "👁️🎩🦯🥛🍊"
    },
    "2025-04-25": {
        "id": "tt1049413",
        "emojiText": "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2025-04-26": {
        "id": "tt0120338",
        "emojiText": "💑🚢🧊🥶👵"
    },
    "2025-04-27": {
        "id": "tt0050083",
        "emojiText": "😠1️⃣2️⃣"
    },
    "2025-04-28": {
        "id": "tt0073195",
        "emojiText": "🦈😱"
    },
    "2025-04-29": {
        "id": "tt0137523",
        "emojiText": "🥊♣️🧼"
    },
    "2025-04-30": {
        "id": "tt0088247",
        "emojiText": "🤖🕶️🔫"
    },
    "2025-05-01": {
        "id": "tt0110413",
        "emojiText": "🧔🔫🥛👧"
    },
    "2025-05-02": {
        "id": "tt0110912",
        "emojiText": "💼🕺💉💃☕⌚"
    },
    "2025-05-03": {
        "id": "tt0083658",
        "emojiText": "🗡️🏃‍♂️"
    },
    "2025-05-04": {
        "id": "tt4729430",
        "emojiText": "📫🧓🔨🧸🛷"
    },
    "2025-05-05": {
        "id": "tt2382320",
        "emojiText": "🚫🕐💀"
    },
    "2025-05-06": {
        "id": "tt1201607",
        "emojiText": "👦⚡☠️⚔️2️⃣"
    },
    "2025-05-07": {
        "id": "tt0095016",
        "emojiText": "🗼🎄👨🔫"
    },
    "2025-05-08": {
        "id": "tt0041959",
        "emojiText": "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2025-05-09": {
        "id": "tt0070047",
        "emojiText": "✝️🤮🙏"
    },
    "2025-05-10": {
        "id": "tt0266697",
        "emojiText": "👱‍♀️⛩️⚔️"
    },
    "2025-05-11": {
        "id": "tt0081505",
        "emojiText": "🪓😈🛀"
    },
    "2025-05-12": {
        "id": "tt10298810",
        "emojiText": "👨‍🚀🌌"
    },
    "2025-05-13": {
        "id": "tt0167404",
        "emojiText": "👁️👀💀🧑‍🤝‍🧑"
    },
    "2025-05-14": {
        "id": "tt9032400",
        "emojiText": "🦸‍♂️🌍♾️"
    },
    "2025-05-15": {
        "id": "tt0317705",
        "emojiText": "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2025-05-16": {
        "id": "tt6751668",
        "emojiText": "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2025-05-17": {
        "id": "tt0266543",
        "emojiText": "🔎🐟"
    },
    "2025-05-18": {
        "id": "tt0075148",
        "emojiText": "🥊"
    },
    "2025-05-19": {
        "id": "tt0112573",
        "emojiText": "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2025-05-20": {
        "id": "tt0816692",
        "emojiText": "🚀🌊⏲️🌽"
    },
    "2025-05-21": {
        "id": "tt0102926",
        "emojiText": "🤐🐑"
    },
    "2025-05-22": {
        "id": "tt0884328",
        "emojiText": "🌫️🛒🌫️"
    },
    "2025-05-23": {
        "id": "tt1375666",
        "emojiText": "💤🌀⤴️"
    },
    "2025-05-24": {
        "id": "tt0060196",
        "emojiText": "👍👎👹"
    },
    "2025-05-25": {
        "id": "tt0198781",
        "emojiText": "🟢👁️🟢👧👹"
    },
    "2025-05-26": {
        "id": "tt0892769",
        "emojiText": "⚫🐉🦷"
    },
    "2025-05-27": {
        "id": "tt0910970",
        "emojiText": "🌍🗑️🤖🌱❤️"
    },
    "2025-05-28": {
        "id": "tt8503618",
        "emojiText": "🇺🇸🎼🗽🎵🔫"
    },
    "2025-05-29": {
        "id": "tt0457430",
        "emojiText": "👁️👐👁️"
    },
    "2025-05-30": {
        "id": "tt0118715",
        "emojiText": "🎳🥛💼🩲"
    },
    "2025-05-31": {
        "id": "tt0169547",
        "emojiText": "🇺🇸💅🌹🌹🌹🌹🌹🌹"
    },
    "2025-06-01": {
        "id": "tt0056172",
        "emojiText": "🏇🏜️🗡️"
    },
    "2025-06-02": {
        "id": "tt1345836",
        "emojiText": "🦇🤵⬆️"
    },
    "2025-06-03": {
        "id": "tt0102926",
        "emojiText": "🔇🐏"
    },
    "2025-06-04": {
        "id": "tt1375666",
        "emojiText": "💡➡😴"
    },
    "2025-06-05": {
        "id": "tt0114709",
        "emojiText": "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2025-06-06": {
        "id": "tt0167260",
        "emojiText": "💍🌋🧙‍♂️👑"
    },
    "2025-06-07": {
        "id": "tt6139732",
        "emojiText": "🧞‍♂️🪔🐒👸🤴"
    },
    "2025-06-08": {
        "id": "tt0211915",
        "emojiText": "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2025-06-09": {
        "id": "tt1745960",
        "emojiText": "✈️🇺🇸🕶️"
    },
    "2025-06-10": {
        "id": "tt0109830",
        "emojiText": "🏃🍫🦐"
    },
    "2025-06-11": {
        "id": "tt0120382",
        "emojiText": "🙍‍♂️🎥⛵😨📺"
    },
    "2025-06-12": {
        "id": "tt4633694",
        "emojiText": "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2025-06-13": {
        "id": "tt0090605",
        "emojiText": "👽🤰🩸😱"
    },
    "2025-06-14": {
        "id": "tt0062622",
        "emojiText": "👁️🖥️🛰️🚀"
    },
    "2025-06-15": {
        "id": "tt0054215",
        "emojiText": "🔪🚿🧓"
    },
    "2025-06-16": {
        "id": "tt0101414",
        "emojiText": "🌹👸🧌"
    },
    "2025-06-17": {
        "id": "tt0264464",
        "emojiText": "🏃‍♂️✈️💰🏃"
    },
    "2025-06-18": {
        "id": "tt0045152",
        "emojiText": "☔🎤😃"
    },
    "2025-06-19": {
        "id": "tt0107290",
        "emojiText": "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2025-06-20": {
        "id": "tt0382932",
        "emojiText": "🐭💆‍♂️👨‍🍳🍲"
    },
    "2025-06-21": {
        "id": "tt10648342",
        "emojiText": "🦸🔨❤️➕⛈️"
    },
    "2025-06-22": {
        "id": "tt0482571",
        "emojiText": "🏃🚪🎩🚪🏃▶️👬"
    },
    "2025-06-23": {
        "id": "tt2380307",
        "emojiText": "🎸💀👦🇲🇽"
    },
    "2025-06-24": {
        "id": "tt0107048",
        "emojiText": "🔄⏰🦔"
    },
    "2025-06-25": {
        "id": "tt0133093",
        "emojiText": "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2025-06-26": {
        "id": "tt1130884",
        "emojiText": "👮‍♂️💊🏝️😨"
    },
    "2025-06-27": {
        "id": "tt0114369",
        "emojiText": "😋😛😴🤢😡😎🤤👀"
    },
    "2025-06-28": {
        "id": "tt5311514",
        "emojiText": "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2025-06-29": {
        "id": "tt0119217",
        "emojiText": "🧹🏫👨‍🏫🧠🍎"
    },
    "2025-06-30": {
        "id": "tt12412888",
        "emojiText": "🦔👟✌️"
    },
    "2025-07-01": {
        "id": "tt0088763",
        "emojiText": "🚗🔙⌚👨‍🔬🙍"
    },
    "2025-07-02": {
        "id": "tt0435761",
        "emojiText": "👨‍🚀🤠🏫🍓🧸"
    },
    "2025-07-03": {
        "id": "tt0116629",
        "emojiText": "🇺🇸🎆👽👊"
    },
    "2025-07-04": {
        "id": "tt0325980",
        "emojiText": "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2025-07-05": {
        "id": "tt6467266",
        "emojiText": "🐷🦍🦁🎹🎧🦔"
    },
    "2025-07-06": {
        "id": "tt1160419",
        "emojiText": "👩‍👦🗡️⏳🐛🪐"
    },
    "2025-07-07": {
        "id": "tt0066921",
        "emojiText": "👁️🎩🦯🥛🍊"
    },
    "2025-07-08": {
        "id": "tt1049413",
        "emojiText": "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2025-07-09": {
        "id": "tt0120338",
        "emojiText": "💑🚢🧊🥶👵"
    },
    "2025-07-10": {
        "id": "tt0050083",
        "emojiText": "😠1️⃣2️⃣"
    },
    "2025-07-11": {
        "id": "tt0073195",
        "emojiText": "🦈😱"
    },
    "2025-07-12": {
        "id": "tt0137523",
        "emojiText": "🥊♣️🧼"
    },
    "2025-07-13": {
        "id": "tt0088247",
        "emojiText": "🤖🕶️🔫"
    },
    "2025-07-14": {
        "id": "tt0110413",
        "emojiText": "🧔🔫🥛👧"
    },
    "2025-07-15": {
        "id": "tt0110912",
        "emojiText": "💼🕺💉💃☕⌚"
    },
    "2025-07-16": {
        "id": "tt0083658",
        "emojiText": "🗡️🏃‍♂️"
    },
    "2025-07-17": {
        "id": "tt4729430",
        "emojiText": "📫🧓🔨🧸🛷"
    },
    "2025-07-18": {
        "id": "tt2382320",
        "emojiText": "🚫🕐💀"
    },
    "2025-07-19": {
        "id": "tt1201607",
        "emojiText": "👦⚡☠️⚔️2️⃣"
    },
    "2025-07-20": {
        "id": "tt0095016",
        "emojiText": "🗼🎄👨🔫"
    },
    "2025-07-21": {
        "id": "tt0041959",
        "emojiText": "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2025-07-22": {
        "id": "tt0070047",
        "emojiText": "✝️🤮🙏"
    },
    "2025-07-23": {
        "id": "tt0266697",
        "emojiText": "👱‍♀️⛩️⚔️"
    },
    "2025-07-24": {
        "id": "tt0081505",
        "emojiText": "🪓😈🛀"
    },
    "2025-07-25": {
        "id": "tt10298810",
        "emojiText": "👨‍🚀🌌"
    },
    "2025-07-26": {
        "id": "tt0167404",
        "emojiText": "👁️👀💀🧑‍🤝‍🧑"
    },
    "2025-07-27": {
        "id": "tt9032400",
        "emojiText": "🦸‍♂️🌍♾️"
    },
    "2025-07-28": {
        "id": "tt0317705",
        "emojiText": "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2025-07-29": {
        "id": "tt6751668",
        "emojiText": "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2025-07-30": {
        "id": "tt0266543",
        "emojiText": "🔎🐟"
    },
    "2025-07-31": {
        "id": "tt0075148",
        "emojiText": "🥊"
    },
    "2025-08-01": {
        "id": "tt0112573",
        "emojiText": "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2025-08-02": {
        "id": "tt0816692",
        "emojiText": "🚀🌊⏲️🌽"
    },
    "2025-08-03": {
        "id": "tt0102926",
        "emojiText": "🤐🐑"
    },
    "2025-08-04": {
        "id": "tt0884328",
        "emojiText": "🌫️🛒🌫️"
    },
    "2025-08-05": {
        "id": "tt1375666",
        "emojiText": "💤🌀⤴️"
    },
    "2025-08-06": {
        "id": "tt0468569",
        "emojiText": "🦸‍♂️🌃🃏🦇"
    },
    "2025-08-07": {
        "id": "tt0071562",
        "emojiText": "🕴️🇮🇹2️⃣🔫🐴"
    },
    "2025-08-08": {
        "id": "tt0120737",
        "emojiText": "💍🏔️🧝‍♂️🧙‍♂️🐉"
    },
    "2025-08-09": {
        "id": "tt0167261",
        "emojiText": "💍2️⃣🏞️🏰🔥"
    },
    "2025-08-10": {
        "id": "tt0080684",
        "emojiText": "🚀⭐⚔️❄️🤖"
    },
    "2025-08-11": {
        "id": "tt0099685",
        "emojiText": "🇮🇹🔫💵🍝👨‍👩‍👦‍👦"
    },
    "2025-08-12": {
        "id": "tt0073486",
        "emojiText": "🦅🛏️🏥🚁🏞️"
    },
    "2025-08-13": {
        "id": "tt0047478",
        "emojiText": "🇯🇵⚔️🌾🏰🌄"
    },
    "2025-08-14": {
        "id": "tt0038650",
        "emojiText": "🏡💕👼🎄💸"
    },
    "2025-08-15": {
        "id": "tt0317248",
        "emojiText": "🌆🇧🇷🔫🏚️"
    },
    "2025-08-16": {
        "id": "tt0120815",
        "emojiText": "🇺🇸🔫🔎👨‍🦱"
    },
    "2025-08-17": {
        "id": "tt0118799",
        "emojiText": "👨‍👦🇮🇹😄🌹"
    },
    "2025-08-18": {
        "id": "tt0103064",
        "emojiText": "🤖🔫2️⃣💥"
    },
    "2025-08-19": {
        "id": "tt0407887",
        "emojiText": "👮‍♂️🔫🐀🇮🇪"
    },
    "2025-08-20": {
        "id": "tt1675434",
        "emojiText": "🇫🇷👨‍🦽👨‍🦱🎵"
    },
    "2025-08-21": {
        "id": "tt0027977",
        "emojiText": "⚙️🏭🕰️😄"
    },
    "2025-08-22": {
        "id": "tt0056058",
        "emojiText": "🇯🇵🗡️🎎🏯"
    },
    "2025-08-23": {
        "id": "tt0064116",
        "emojiText": "🤠🏜️🚂🔫"
    },
    "2025-08-24": {
        "id": "tt0047396",
        "emojiText": "👀🔭🏢🚔"
    },
    "2025-08-25": {
        "id": "tt0078748",
        "emojiText": "👽🚀🏭🌌"
    },
    "2025-08-26": {
        "id": "tt0095765",
        "emojiText": "🎥🎞️🇮🇹💔"
    },
    "2025-08-27": {
        "id": "tt0209144",
        "emojiText": "⏮️🧩🔍📸"
    },
    "2025-08-28": {
        "id": "tt0405094",
        "emojiText": "🇩🇪👀🎧📝"
    },
    "2025-08-29": {
        "id": "tt0043014",
        "emojiText": "🌅🛣️🎥👩‍🦳"
    },
    "2025-08-30": {
        "id": "tt0050825",
        "emojiText": "🥾🏞️🎖️🇫🇷"
    },
    "2025-08-31": {
        "id": "tt0032553",
        "emojiText": "🌍🎩👨‍⚖️🎈"
    },
    "2025-09-01": {
        "id": "tt0051201",
        "emojiText": "🧐👩‍⚖️🕵️‍♂️📝"
    },
    "2025-09-02": {
        "id": "tt0036775",
        "emojiText": "🔄🔍💰👫"
    },
    "2025-09-03": {
        "id": "tt0053604",
        "emojiText": "🏢💘👨‍💼"
    },
    "2025-09-04": {
        "id": "tt0086250",
        "emojiText": "👨💊🔫🏙️"
    },
    "2025-09-05": {
        "id": "tt0044741",
        "emojiText": "👨🏻‍💼⏳🎢"
    },
    "2025-09-06": {
        "id": "tt0070735",
        "emojiText": "🎩💰🃏"
    },
    "2025-09-07": {
        "id": "tt0056592",
        "emojiText": "🕊️⚖️👧👦"
    },
    "2025-09-08": {
        "id": "tt0119488",
        "emojiText": "🔎🚔🚬🌆"
    },
    "2025-09-09": {
        "id": "tt1832382",
        "emojiText": "👫🏻💔🇮🇷"
    },
    "2025-09-10": {
        "id": "tt0208092",
        "emojiText": "💎🥊🐷"
    },
    "2025-09-11": {
        "id": "tt1255953",
        "emojiText": "🔥👩‍👧‍👦📜"
    },
    "2025-09-12": {
        "id": "tt0097576",
        "emojiText": "🤠🏺✝️"
    },
    "2025-09-13": {
        "id": "tt0040522",
        "emojiText": "🚲❌👨‍👦"
    },
    "2025-09-14": {
        "id": "tt8579674",
        "emojiText": "🌍⏳🔫🕊️1️⃣7️⃣"
    },
    "2025-09-15": {
        "id": "tt0986264",
        "emojiText": "👦📚🌟"
    },
    "2025-09-16": {
        "id": "tt0363163",
        "emojiText": "🇩🇪👨‍✈️⬇️"
    },
    "2025-09-17": {
        "id": "tt10872600",
        "emojiText": "🕷️👦🚫🏠"
    },
    "2025-09-18": {
        "id": "tt0059578",
        "emojiText": "💰💰🔫"
    },
    "2025-09-19": {
        "id": "tt0372784",
        "emojiText": "🦇👦🏙️"
    },
    "2025-09-20": {
        "id": "tt5074352",
        "emojiText": "🤼‍♀️🏅🇮🇳"
    },
    "2025-09-21": {
        "id": "tt0012349",
        "emojiText": "🧒🎩👫"
    },
    "2025-09-22": {
        "id": "tt10272386",
        "emojiText": "👴🧠🔄"
    },
    "2025-09-23": {
        "id": "tt0042192",
        "emojiText": "🎭👩🔝"
    },
    "2025-09-24": {
        "id": "tt6966692",
        "emojiText": "🚗🎵📗"
    },
    "2025-09-25": {
        "id": "tt0055031",
        "emojiText": "⚖️🇩🇪🕰️"
    },
    "2025-09-26": {
        "id": "tt0042876",
        "emojiText": "🌳🔪🎌🎭"
    },
    "2025-09-27": {
        "id": "tt0477348",
        "emojiText": "🏜️💼💰🔫"
    },
    "2025-09-28": {
        "id": "tt0080678",
        "emojiText": "🎩🐘😭💔"
    },
    "2025-09-29": {
        "id": "tt0071315",
        "emojiText": "🕵️🏙️💔🔪"
    },
    "2025-09-30": {
        "id": "tt0031381",
        "emojiText": "👩💔🌪️🏡"
    },
    "2025-10-01": {
        "id": "tt0434409",
        "emojiText": "✌️🎭💣🇬🇧"
    },
    "2025-10-02": {
        "id": "tt0084787",
        "emojiText": "❄️👾😱🔥"
    },
    "2025-10-03": {
        "id": "tt0120735",
        "emojiText": "🔒💼🔫💰"
    },
    "2025-10-04": {
        "id": "tt0046912",
        "emojiText": "☎️🔪😱🕵️"
    },
    "2025-10-05": {
        "id": "tt1305806",
        "emojiText": "👁️🔍💔⏳"
    },
    "2025-10-06": {
        "id": "tt0050212",
        "emojiText": "🌉🏞️⛓️💣"
    },
    "2025-10-07": {
        "id": "tt0347149",
        "emojiText": "🧙‍♂️🏰🔮🐺"
    },
    "2025-10-08": {
        "id": "tt5027774",
        "emojiText": "3️⃣🛑🔥💔"
    },
    "2025-10-09": {
        "id": "tt1205489",
        "emojiText": "🚗👴🔫🏠"
    },
    "2025-10-10": {
        "id": "tt1291584",
        "emojiText": "🤼‍♂️🥊👨‍👦‍👦🏆"
    },
    "2025-10-11": {
        "id": "tt0116282",
        "emojiText": "❄️🚗💵🔫"
    },
    "2025-10-12": {
        "id": "tt1392214",
        "emojiText": "👭🚐👮‍♂️🔍"
    },
    "2025-10-13": {
        "id": "tt0405159",
        "emojiText": "🥊💵🏆👧"
    },
    "2025-10-14": {
        "id": "tt0015864",
        "emojiText": "⛏️🏔️🤑🍞"
    },
    "2025-10-15": {
        "id": "tt0047296",
        "emojiText": "🚢⚓️🥊🐦"
    },
    "2025-10-16": {
        "id": "tt0118849",
        "emojiText": "🧒👟🏃‍♂️🏅"
    },
    "2025-10-17": {
        "id": "tt0052618",
        "emojiText": "👑🐎🛡️⛓️"
    },
    "2025-10-18": {
        "id": "tt0017925",
        "emojiText": "🚂👨‍✈️💣💔"
    },
    "2025-10-19": {
        "id": "tt0112471",
        "emojiText": "👫🌇💬❤️"
    },
    "2025-10-20": {
        "id": "tt0050986",
        "emojiText": "🍓👴🚗💭"
    },
    "2025-10-21": {
        "id": "tt2267998",
        "emojiText": "👩💍💌🔪"
    },
    "2025-10-22": {
        "id": "tt0077416",
        "emojiText": "🦌🏹🔫🇻🇳"
    },
    "2025-10-23": {
        "id": "tt0107207",
        "emojiText": "👨‍👦💣⚖️🇬🇧"
    },
    "2025-10-24": {
        "id": "tt3011894",
        "emojiText": "🦊📖💥💔"
    },
    "2025-10-25": {
        "id": "tt1392190",
        "emojiText": "😡🚗💥🏜️"
    },
    "2025-10-26": {
        "id": "tt0978762",
        "emojiText": "👧💌👴🇦🇺"
    },
    "2025-10-27": {
        "id": "tt0019254",
        "emojiText": "👩‍🌾🙏🔥⛪"
    },
    "2025-10-28": {
        "id": "tt0097165",
        "emojiText": "👨‍🏫📚💭🕊️"
    },
    "2025-10-29": {
        "id": "tt0395169",
        "emojiText": "🏨🇷🇼👨‍👩‍👧‍👦🔫"
    },
    "2025-10-30": {
        "id": "tt0046438",
        "emojiText": "👵👴🌆🎌"
    },
    "2025-10-31": {
        "id": "tt0048473",
        "emojiText": "👨‍👩‍👧‍👦🏞️🇮🇳💔"
    },
    "2025-11-01": {
        "id": "tt0091763",
        "emojiText": "👨‍👦‍👦🔫🌴🇻🇳"
    },
    "2025-11-02": {
        "id": "tt1950186",
        "emojiText": "🚘⚙️🏁🏆"
    },
    "2025-11-03": {
        "id": "tt0092005",
        "emojiText": "👦👦👦👦🛤️👑"
    },
    "2025-11-04": {
        "id": "tt1895587",
        "emojiText": "🔍📰⛪👥"
    },
    "2025-11-05": {
        "id": "tt1979320",
        "emojiText": "🏎️💨⏱️🏆"
    },
    "2025-11-06": {
        "id": "tt0074958",
        "emojiText": "📺💬🏙️🎭"
    },
    "2025-11-07": {
        "id": "tt0758758",
        "emojiText": "🚶‍♂️🌲🚌🌄"
    },
    "2025-11-08": {
        "id": "tt3315342",
        "emojiText": "👨🔪🚗👧"
    },
    "2025-11-09": {
        "id": "tt0381681",
        "emojiText": "💑🌅🇫🇷💬"
    },
    "2025-11-10": {
        "id": "tt0036868",
        "emojiText": "🇺🇸🎖️👨‍👩‍👧‍👦❤️"
    },
    "2025-11-11": {
        "id": "tt0032976",
        "emojiText": "👰🏰🔥💔"
    },
    "2025-11-12": {
        "id": "tt0058946",
        "emojiText": "🇩🇿💣🔫🏙️"
    },
    "2025-11-13": {
        "id": "tt0032551",
        "emojiText": "🍇😡👨‍👩‍👧‍👦🚚"
    },
    "2025-11-14": {
        "id": "tt0061512",
        "emojiText": "😎✋👷🥚"
    },
    "2025-11-15": {
        "id": "tt0245712",
        "emojiText": "🐶❤️🚗💥"
    },
    "2025-11-16": {
        "id": "tt0113247",
        "emojiText": "👨‍👨‍👦🏙️🔫💔"
    },
    "2025-11-17": {
        "id": "tt0053198",
        "emojiText": "👦💔🏃‍♂️🇫🇷"
    },
    "2025-11-18": {
        "id": "tt0060827",
        "emojiText": "👩‍⚕️👩🎭🏖️"
    },
    "2025-11-19": {
        "id": "tt0476735",
        "emojiText": "👨👦❤️💔"
    },
    "2025-11-20": {
        "id": "tt0079470",
        "emojiText": "👨‍🦱🙌✨😂"
    },
    "2025-11-21": {
        "id": "tt0025316",
        "emojiText": "🌃👫🚌💘"
    },
    "2025-11-22": {
        "id": "tt0059742",
        "emojiText": "🎵🏞️👩‍👧‍👦🇦🇹"
    },
    "2025-11-23": {
        "id": "tt0071411",
        "emojiText": "🧑🏔️🌲🇷🇺"
    },
    "2025-11-24": {
        "id": "tt4016934",
        "emojiText": "👩‍❤️‍👩📘🇰🇷🏰"
    },
    "2025-11-25": {
        "id": "tt15097216",
        "emojiText": "⚖️🇮🇳💼🗣️"
    },
    "2025-11-26": {
        "id": "tt0083987",
        "emojiText": "🇮🇳👳‍♂️☮️📚"
    },
    "2025-11-27": {
        "id": "tt1454029",
        "emojiText": "👩‍🦰📚👩‍🦱🥧"
    },
    "2025-11-28": {
        "id": "tt3704428",
        "emojiText": "🕺🎤🎸🇺🇸"
    },
    "2025-11-29": {
        "id": "tt5113044",
        "emojiText": "🍌🦹‍♂️🏢🌆"
    },
    "2025-11-30": {
        "id": "tt10304142",
        "emojiText": "🚲🌷🇳🇱🏛️"
    },
    "2025-12-01": {
        "id": "tt9419884",
        "emojiText": "🧙‍♂️💫🌀🌌"
    },
    "2025-12-02": {
        "id": "tt7144666",
        "emojiText": "📞⚫🕵️‍♂️👦"
    },
    "2025-12-03": {
        "id": "tt8041270",
        "emojiText": "🦖🌍🏰🦕"
    },
    "2025-12-04": {
        "id": "tt4123432",
        "emojiText": "🔮🐉🧙📜"
    },
    "2025-12-05": {
        "id": "tt21059520",
        "emojiText": "👧🖼️📷🔍"
    },
    "2025-12-06": {
        "id": "tt13841850",
        "emojiText": "👨👨‍🦱👨‍🦳🕴️"
    },
    "2025-12-07": {
        "id": "tt8093700",
        "emojiText": "👩👑⚔️🏰"
    },
    "2025-12-08": {
        "id": "tt7131622",
        "emojiText": "🎬🌟🌴💫"
    },
    "2025-12-09": {
        "id": "tt13320622",
        "emojiText": "🗺️🏙️🔍🕵️‍♂️"
    },
    "2025-12-10": {
        "id": "tt12593682",
        "emojiText": "🚄🔫💥🎯"
    },
    "2025-12-11": {
        "id": "tt14549466",
        "emojiText": "🔮🚀🔬⚖️"
    },
    "2025-12-12": {
        "id": "tt5108870",
        "emojiText": "🩸🦇🔬🧛‍♂️"
    },
    "2025-12-13": {
        "id": "tt1649418",
        "emojiText": "👤🔫🕵️‍♂️💼"
    },
    "2025-12-14": {
        "id": "tt11128440",
        "emojiText": "👔💼🛍️🗣️"
    },
    "2025-12-15": {
        "id": "tt8178634",
        "emojiText": "🇮🇳🔥⚔️🛤️"
    },
    "2025-12-16": {
        "id": "tt9851854",
        "emojiText": "👮‍♂️🎖️🇮🇳🔫"
    },
    "2025-12-17": {
        "id": "tt2231253",
        "emojiText": "🃏🎲🔫💰"
    },
    "2025-12-18": {
        "id": "tt4998632",
        "emojiText": "🚑🚨🏥🚔"
    },
    "2025-12-19": {
        "id": "tt1323594",
        "emojiText": "😈👧👧👧🍌"
    },
    "2025-12-20": {
        "id": "tt14376344",
        "emojiText": "💃🕺🎶🎈"
    },
    "2025-12-21": {
        "id": "tt8323668",
        "emojiText": "👴👵💰🎰"
    },
    "2025-12-22": {
        "id": "tt0089218",
        "emojiText": "🏴‍☠️🗺️💎👦"
    },
    "2025-12-23": {
        "id": "tt4181158",
        "emojiText": "👋👋💔💌"
    },
    "2025-12-24": {
        "id": "tt1981115",
        "emojiText": "🔨⚡🌌👑"
    },
    "2025-12-25": {
        "id": "tt14145426",
        "emojiText": "👦👦🚀🌌"
    },
    "2025-12-26": {
        "id": "tt0134119",
        "emojiText": "🎩🔎💼🔪"
    },
    "2025-12-27": {
        "id": "tt1879016",
        "emojiText": "💼🕵️‍♂️📜🕹️"
    },
    "2025-12-28": {
        "id": "tt10954652",
        "emojiText": "👵🏝️⏳👴"
    },
    "2025-12-29": {
        "id": "tt12545566",
        "emojiText": "📜🎩💕💌"
    }
};

//
// const keys = Object.keys(DAILY_EMOVI);
//
// let day = dayjs();
// for(const key of keys) {
//     DAILY_EMOVI[day.format('YYYY-MM-DD')] = DAILY_EMOVI[key];
//     day = day.add(1,'day');
// }
// console.log(JSON.stringify(DAILY_EMOVI, null, 2));
