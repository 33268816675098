import { Link } from "react-router-dom";
import { EmoviToGuess } from "../interfaces/EmoviToGuess";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import UserContext from "../UserProvider";
import { allMovies } from "../movies";
import { toast } from "react-toastify";
import { saveStatsToDB } from "../services/stats";
import { buildShareUrl, getDayStringNegOne, getSavedGuesses } from "../services/utilities";
import { Twemoji } from "react-emoji-render";
import Select from "react-select";
import { MovieCard } from "./MovieCard";
import CopyToClipboard from "react-copy-to-clipboard";

interface savedGuesses {
    invalidGuessIds: string[],
    movieGuessed: boolean
}

const MAX_TRIES = 3;

export function GuessAEmovi({
    emoviToGuess,
    dailyNumber,
    dayString,
    isArchive
}: {
    emoviToGuess: EmoviToGuess;
    dailyNumber?: number;
    dayString?: string;
    isArchive?: boolean;
}) {
    const { userDetails } = useContext(UserContext);
    const [tempSavedGuesses, setTempSavedGuesses] = useState<savedGuesses>({
        invalidGuessIds: [],
        movieGuessed: false
    });

    const movieToGuess = useMemo(() => {
        return emoviToGuess && allMovies.find((m) => m.id === emoviToGuess.id);
    }, [emoviToGuess]);

    const selectOptions = useMemo(() => {
        return allMovies.map((m) => ({
            value: m.id,
            label: m.title,
        }));
    }, []);

    const [selectedOption, setSelectedOption] = useState<{
        value: string;
        label: string;
    } | null>(null);

    const initialSavedGuess = useMemo(() => {
        if (!dayString) {
            return undefined;
        }
        if(isArchive) {
            return tempSavedGuesses;
        }
        const savedGuesses = getSavedGuesses();
        return savedGuesses[dayString];
    }, [dayString]);
    
    const [invalidGuessIds, setInvalidGuessIds] = useState<string[]>(
        initialSavedGuess?.invalidGuessIds ?? []
    );
    const setInitialMovieGuessed = () => {
        if (initialSavedGuess?.movieGuessed && !isArchive) return true;
        return false;
    }
    const [movieGuessed, setMovieGuessed] = useState<boolean>(
        setInitialMovieGuessed()
    );
    const [movieFailed, setMovieFailed] = useState<boolean>(
        false
    );


    const selectMovieRef = useRef<any>(null);
    const handleGetHint = useCallback(() => {
        setInvalidGuessIds((prev) => {
            const updatedInvalidGuessIds = [...prev, ""];
            if (dayString && !isArchive) {
                const guesses = getSavedGuesses();
                guesses[dayString]!.invalidGuessIds = updatedInvalidGuessIds;
                localStorage.setItem('guesses', JSON.stringify(guesses));
            }
            if (updatedInvalidGuessIds.length >= 3) {
                setMovieFailed(true);
            }
            return updatedInvalidGuessIds;
        });
    }, [dayString]);
    const handleGuess = useCallback(() => {

        if (!selectedOption) {
            selectMovieRef.current?.focus();
            return;
        }
        if (emoviToGuess && emoviToGuess.id === selectedOption?.value) {
            setMovieGuessed((prev) => {
                if (emoviToGuess && emoviToGuess.id === selectedOption?.value) {
                    toast.success("You guessed it!");

                    if (dayString && !isArchive) {
                        const guesses = getSavedGuesses();
                        guesses[dayString]!.movieGuessed = true;
                        localStorage.setItem('guesses', JSON.stringify(guesses));
                    }
                    return true;
                }
                return prev;
            });

            toast.success("You guessed it!");
        } else {
            console.log('setting invalid guess...');
            setInvalidGuessIds((prev) => {
                const updatedInvalidGuessIds = [...prev, selectedOption?.value];
                if (dayString && !isArchive) {
                    const guesses = getSavedGuesses();
                    guesses[dayString]!.invalidGuessIds = updatedInvalidGuessIds;
                    localStorage.setItem('guesses', JSON.stringify(guesses));
                }
                if (updatedInvalidGuessIds.length >= 3) {
                    setMovieFailed(true);
                }
                return updatedInvalidGuessIds;
            });
            toast.error("Wrong movie!");
            setSelectedOption(null);
        }

    }, [dayString, emoviToGuess, selectedOption]);

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender && !isArchive) {
            console.log("isInitialRender");
            if (!dayString) {
                return;
            }

            const guesses = getSavedGuesses();

            if (!guesses[dayString]) {
                guesses[dayString] = {
                    movieGuessed: false,
                    invalidGuessIds: [],
                };
            }

            guesses[dayString]!.movieGuessed = movieGuessed;
            guesses[dayString]!.invalidGuessIds = invalidGuessIds;
            localStorage.setItem('guesses', JSON.stringify(guesses));

            setIsInitialRender(false);
        }
    }, [dayString, movieGuessed, invalidGuessIds, isInitialRender]);


    useEffect(() => {
        // Game over, try and save stats
        if (movieGuessed || movieFailed) {
            saveStatsToDB(userDetails);
        }
    }, [movieGuessed, userDetails, movieFailed]);

    const { statsUpdated } = useContext(UserContext);

    useEffect(() => {
        if (isArchive) return;
        const guesses = getSavedGuesses();
        const dayString = getDayStringNegOne();
        if (guesses[dayString]) {
            const currentGuess = guesses[dayString];
            if (currentGuess && currentGuess.movieGuessed) {
                setMovieGuessed(true);
            }
            if (currentGuess && currentGuess.invalidGuessIds.length >= 3) {
                setMovieFailed(true);
            }
        }
        console.log("stats have been updated...");

    }, [statsUpdated]);

    const shareText = useMemo(() => {
        if (!emoviToGuess || !movieToGuess) {
            return "";
        }

        return [
            `#Emovi 🎬${dailyNumber ? ` #${dailyNumber}` : ""}`,
            emoviToGuess.emojiText,
            "🟥".repeat(invalidGuessIds.length) +
            (movieGuessed ? "🟩" : "") +
            "⬜".repeat(Math.max(MAX_TRIES - invalidGuessIds.length - 1, 0)),
            dayString ? window.location.origin : buildShareUrl(emoviToGuess),
        ].join("\n");
    }, [
        dailyNumber,
        dayString,
        emoviToGuess,
        invalidGuessIds.length,
        movieGuessed,
        movieToGuess,
    ]);

    return (
        <div className="flex flex-col items-center gap-2 w-full">
            <div className="flex flex-col items-center w-full">
                <p className="text-lg font-bold text-center">Guess this movie:</p>
                {emoviToGuess && (
                    <p className="text-3xl text-center">
                        <Twemoji
                            text={emoviToGuess.emojiText}
                            options={{ className: "inline-block" }}
                        />
                    </p>
                )}
                {emoviToGuess.author && (
                    <p className="text-center">
                        by{" "}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700 font-semibold"
                            href={`https://twitter.com/${emoviToGuess.author}`}
                        >
                            {emoviToGuess.author}
                        </a>
                    </p>
                )}
            </div>
            {!movieGuessed && !movieFailed ? (
                <div className="flex flex-col gap-4 items-center w-full">
                    <Select
                        ref={selectMovieRef}
                        openMenuOnFocus
                        openMenuOnClick
                        className="w-full"
                        options={selectOptions}
                        onChange={setSelectedOption}
                        value={selectedOption}
                    />
                    <div className="flex flex-row-reverse gap-2 w-full ">
                        <button
                            onClick={handleGuess}
                            className="bg-blue-500 hover:bg-blue-700 disabled:bg-gray-300 text-white font-bold py-4 px-4 rounded basis-2/3"
                        >
                            Guess! ({invalidGuessIds.length + 1} / {MAX_TRIES})
                        </button>
                        <button
                            onClick={handleGetHint}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-4 rounded basis-1/3"
                        >
                            {invalidGuessIds.length >= MAX_TRIES - 1
                                ? "Give up..."
                                : "Get a hint..."}
                        </button>
                    </div>
                    {invalidGuessIds.length > 0 && (
                        <div className="w-full flex justify-start items-start">
                            <div className="flex-shrink-0 basis-32 font-bold whitespace-nowrap">
                                Hint #1 - Year:
                            </div>
                            <div className="col-span-2">{movieToGuess?.year}</div>
                        </div>
                    )}
                    {invalidGuessIds.length > 1 && (
                        <div className="w-full flex justify-start items-start">
                            <div className="flex-shrink-0 basis-32 font-bold whitespace-nowrap">
                                Hint #2 - Crew:
                            </div>
                            <div className="col-span-2">{movieToGuess?.crew}</div>
                        </div>
                    )}
                    <div className="mt-16 flex flex-col gap-2 items-center">
                        {!dailyNumber && (
                            <>
                                <Link
                                    to="/"
                                    className="text-blue-500 hover:text-blue-700 font-bold"
                                >
                                    Go to the daily Emovi
                                </Link>
                                <p>or</p>
                            </>
                        )}
                        <Link
                            to="/make"
                            className="text-blue-500 hover:text-blue-700 font-bold"
                        >
                            Create your own Emovi
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-2 text-center w-full">
                    {movieToGuess && <MovieCard movie={movieToGuess} />}
                    <div>
                        {movieGuessed ? (
                            <>
                                <p>Well done! You guessed the movie!</p>
                                <p>In {invalidGuessIds.length + 1} {invalidGuessIds.length + 1 === 1 ? 'guess' : 'guesses'}!</p>
                            </>
                        ) : (
                            <p>You failed to guess the movie...</p>
                        )}
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <CopyToClipboard
                            text={shareText}
                            onCopy={() => toast("Result copied to clipboard")}
                            options={{
                                format: "text/plain",
                            }}
                        >
                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full">
                                Share your result
                            </button>
                        </CopyToClipboard>
                        <div className="grid grid-cols-2 gap-1">
                            <Link
                                to="/archive"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                            >
                                Play the archive
                            </Link>
                            <Link
                                to="/replay"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                            >
                                Replay today's game
                            </Link>

                        </div>
                        {!dailyNumber && (
                            <Link
                                to="/"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                            >
                                Go to the daily Emovi
                            </Link>
                        )}
                    </div>
                    {dayString && (
                        <div className="py-4 font-bold flex flex-col">
                            <span><Link to={'/stats'} className="text-blue-500 hover:text-blue-600">See full stats</Link></span>

                        </div>
                    )}
                </div>
            )}
        </div>
    );
}