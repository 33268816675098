import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { DAILY_EMOVI } from "../../data/games";
import UserContext from "../../UserProvider";
import { useContext } from "react";
import { BecomePremiumMember } from "../../components/BecomeAMember";

export function ArchiveEmoviRoute() {

    const navigate = useNavigate();
    const { userDetails } = useContext(UserContext);
    let gameNumber = 0;
    let games = Object.keys(DAILY_EMOVI).sort().map(date => {
        return {
            game: gameNumber++,
            date,
            text: DAILY_EMOVI[date].emojiText,
        }
    });
    games = games.filter(game => dayjs(game.date).isBefore(dayjs())).reverse();
    // Remove last element as it is game 0
    games.pop();

    const playRandomGame = () => {
        const randomIndex = Math.floor(Math.random() * games.length);
        const randomGame = games[randomIndex];
        console.log(randomGame);
        navigate(`/archive/${randomGame.date}`);
    }

    return (
        <>
            <div className="px-4 md:px-0">
                <div className="mb-4 mt-4">
                    <Link to="/">
                        <span className="text-blue-500 hover:text-blue-400">
                            &#8592; Back
                        </span>
                    </Link>
                </div>
                <h2 className="text-xl font-bold mb-2">Archive</h2>
                {!userDetails?.subscription?.isPremium && <>
                    <p className="mb-3">Access to the game archive is an exclusive feature for Premium Members. Register now through the link below and unlock the ability to play all the games that have ever existed!</p>
                    <div className="flex flex-col gap-2 mb-3">
                        <BecomePremiumMember />
                    </div>
                </>}
                <div className="flex flex-col gap-2">
                    {userDetails?.subscription?.isPremium && (<p className="mb-3">Here are all the games since 17th July 2022.</p>)}
                    <div onClick={playRandomGame} className="text-center border border-black p-2 rounded-md shadow-sm hover:bg-slate-200 cursor-pointer mb-2">Play a random game</div>
                    <div className="grid grid-cols-2 gap-2">
                        {games.map((game, i) => (
                            <Link to={`/archive/${game.date}`} key={i}>
                                <div key={game.game} className={`text-center border border-black p-2 rounded-md shadow-sm hover:bg-slate-200 cursor-pointer ${!userDetails?.subscription?.isPremium ? 'cursor-not-allowed' : ''}`}>
                                    <p>Game {game.game}</p>
                                    <p>{game.text}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
