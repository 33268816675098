import React from 'react';

export const BecomePremiumMember = () => {

  const signUpToPremium = () => {
    if (window.location.href.includes('localhost')) {
      window.location.href = 'http://localhost:3000/premium-sign-up';
    } else if(window.location.href.includes('staging')) {
        window.location.href = 'https://staging-account.teuteuf.fr/premium-sign-up';
    }
    else {
      window.location.href = 'https://account.teuteuf.fr/premium-sign-up';
    }
  }

  return (
    <div className="p-4 bg-slate-700 text-white rounded w-full text-center flex flex-col justify-center items-center gap-1 shadow-md">
      <h5 className="text-2xl font-bold">Become a Teuteuf Premium Member</h5>
      <div className="grid md:grid-cols-2 gap-2 mt-2">
        <div className="flex gap-2 items-center justify-center">
          <img src="/tick.png" alt="tick" height="30" width="30" className="invert h-5 w-5" />
          <p>No ads</p>
        </div>
        <div className="flex gap-2 items-center justify-center">
          <img src="/tick.png" alt="tick" height="30" width="30" className="invert h-5 w-5" />
          <p>Replay games</p>
        </div>
        <div className="flex gap-2 items-center justify-center">
          <img src="/tick.png" alt="tick" height="30" width="30" className="invert h-5 w-5" />
          <p>Play the archive</p>
        </div>
        <div className="flex gap-2 items-center justify-center">
          <img src="/tick.png" alt="tick" height="30" width="30" className="invert h-5 w-5" />
          <p>Dedicated support</p>
        </div>
      </div>
      <button onClick={signUpToPremium} className="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded mt-3">Sign up now</button>
    </div>
  );
};
